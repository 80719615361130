import { Stack, Box } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";

export default function Definition({
  title,
  body,
  image,
  subTitle,
  body2,
  collapse,
  initialLength,
}) {
  const { primary } = useThemePalette();
  return (
    <Stack bgcolor={primary} sx={{ py: 3 }}>
      <AppContainer sx={{ gap: 2 }}>
        <Stack gap={4}>
          <Title text={title} sx={{ textAlign: "center" }} />
          <Body text={body} />
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={3}
        >
          <Stack
            gap={3}
            width={{ xs: "100%", md: "55%" }}
            sx={{ textAlign: { xs: "center", md: "start" } }}
          >
            <Title text={subTitle} sx={{ fontFamily: "Alumni Sans", fontSize: "36px" }} />
            <Body
              text={body2}
              collapse={collapse}
              initialLength={initialLength}
              sx={{ textAlign: { xs: "center", md: "start" } }}
            />
          </Stack>
          <Stack width={{ xs: "100%", sm: "70%", md: "28%" }}>
            <Box component="img" src={image} width="100%" />
          </Stack>
        </Stack>
      </AppContainer>
    </Stack>
  );
}
