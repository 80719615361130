import AppContainer from "components/Atoms/Container/AppContainer";
import { useThemePalette } from "common/hooks/theme_palette";
import Title from "components/Molecules/Section/Title";
import Body from "components/Molecules/Section/Body";
import { Stack, Typography } from "@mui/material";
import CustomSelect from "components/Atoms/Select/CustomSelect";
import { useEffect, useState } from "react";

export default function SMSPricing({ title, body, prices }) {
  const { primary ,secondary} = useThemePalette();
  const [country, setCoutnry] = useState("");
  const [network, setNetwork] = useState("");

  const countries = Array.from(new Set(prices?.map((item) => item.country))); //extracting unique countries
  const networks = prices?.filter((item) => item.country == country).map((item) => item.network);
  const price = prices?.find((item) => item.country == country && item.network == network)?.rate;

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "country") {
      setCoutnry(value);
      setNetwork(networks[0]);
    } else if (name === "network") {
      setNetwork(value);
    }
  };

  //initial values
  useEffect(() => {
    if (prices) {
      setCoutnry(prices[0]?.country);
      setNetwork(prices[0]?.network);
    }
  }, [prices]);

  return (
    <Stack bgcolor={primary}>
      <AppContainer gap={4} py={2}>
        <Stack sx={{ gap: 2 }}>
          <Title text={title} sx={{ textAlign: { xs: "center", md: "center" } }} />
          <Body
            text={body}
            sx={{
              textAlign: { xs: "center", md: "center" },
              fontWeight: 400,
            }}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} alignItems="end" gap={2}>
          <CustomSelect
            label="Country"
            value={country || ""}
            name="country"
            handleChange={handleChange}
            options={countries}
            width={{ xs: "100%", md: "45%" }}
            sx={{ fontFamily: "Alef", fontWeight: 700, fontSize: "16px",backgroundColor:secondary }}
          />
          <CustomSelect
            label="Network"
            name="network"
            value={network || ""}
            handleChange={handleChange}
            options={networks}
            width={{ xs: "100%", md: "45%" }}
            sx={{ fontFamily: "Alef", fontWeight: 700, fontSize: "16px" ,backgroundColor:secondary}}
          />
          <Stack sx={{ width: { xs: "100%", md: "45%" } }} gap={1}>
            <Typography>Price/Per message </Typography>
            <Stack
              sx={{
                border: "1px solid #B3B3B3",
                height: "55px",
                borderRadius: "36px",
                justifyContent: "center",
                px: 2,
                backgroundColor:secondary
              }}
            >
              <Typography fontFamily="Alef" fontWeight={700} fontSize="16px">
                ${price}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </AppContainer>
    </Stack>
  );
}
