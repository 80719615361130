import { Box, Stack } from "@mui/material";
import Title from "../Section/Title";
import Body from "../Section/Body";

export default function CommunicationSolutionsCard({ card }) {
  const cardStyle = {
    border: "double 4px transparent",
    borderRadius: "30px",
    backgroundImage:
      "linear-gradient(white, white),radial-gradient(circle at top left, #94f3da, #eaaec8)",
    backgroundOrigin: "border-box",
    backgroundClip: "padding-box, border-box",
    flexDirection: { xs: "column", md: "row" },
    width: { xs: "100%", sm: "45%" },
    mx: "auto",
    minHeight: "186px",
  };
  return (
    <Stack sx={cardStyle}>
      {/* -----------IMAGE----------- */}
      <Stack
        sx={{
          width: { xs: "60%", md: "25%", lg: "27%", xl: "20%" },
          height: "90%",
          ml: { xs: 0, md: -3 },
          mt: { xs: -10, sm: 1 },
          mx:{xs:'auto'}
        }}
      >
        <Box
          component="img"
          src={card.img.src}
          alt={card.img.alt}
          width="100%"
          height="100%"
          sx={{ mt: "auto" }}
        />
      </Stack>
      {/* -----------CONTENT----------- */}
      <Stack
        sx={{
          ml: { xs: 0, md: "auto" },
          mr: { xs: 0, md: 0, lg: 5 },
          width: { xs: "100%", md: "80%", lg: "60%" },
          gap: 3,
          pt: 2,
          textAlign: { xs: "center", md: "start" },
          justifyContent: "start",
        }}
      >
        <Title text={card.title} sx={{ fontFamily: "Alef", fontSize: "16px" }} />
        <Body
          text={card.body}
          sx={{
            fontSize: "16px",
            fontFamily: "Alef",
            textAlign: { xs: "center", md: "start" },
            lineHeight: { xs: "27px", md: "23px" },
          }}
        />
      </Stack>
    </Stack>
  );
}
