import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

export default function BasicDatePicker({
  inputFormat = "DD-MM-YYYY",
  value,
  onChange,
  name,
  id,
  sx,
}) {
  return (
    <DesktopDatePicker
      disableFuture
      name={name}
      id={id}
      value={typeof value === "undefined" ? null : value}
      onChange={onChange}
      sx={{
        ".MuiPickersDesktopDateRangeCalendarHeader": {
          border: "1px solid red !important",
        },
      }}
      renderInput={(params) => (
        <TextField
          sx={{ border: "none !important" }}
          {...params}
          InputProps={{
            ...params.InputProps,
            inputProps: {
              ...params.inputProps,
              readOnly: true, // make the input read-only
              style: { visibility: value ? "visible" : "hidden" }, // hide the input
            },
          }}
        />
      )}
      format={inputFormat}
    />
  );
}
