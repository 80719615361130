import { icons } from "assets/AssetHelper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ResalaBaseURL, ResalaPassword, ResalaUsername } from "services/modules/utils/constant";
import NumbersPricingTemplate from "templates/pricing/numbers/NumbersPricing.Template";
import { formFields, initialValues, validationSchema } from "./form";

export default function NumbersPricingPage({ size }) {
  const temporary = [
    {
      "cscd": "Kazakhstan",
      "rate1": 0.041
    },
    {
      "cscd": "Kazakhstan Cellular-Astel",
      "rate1": 0.235
    },
    {
      "cscd": "Kazakhstan Cellular-K-Cell",
      "rate1": 0.185
    },
    {
      "cscd": "Kazakhstan Cellular-K-Cell",
      "rate1": 0.185
    },
    {
      "cscd": "Kazakhstan Cellular-Beeline",
      "rate1": 0.215
    },
    {
      "cscd": "Kazakhstan Cellular-Beeline",
      "rate1": 0.215
    },
    {
      "cscd": "Kazakhstan Cellular-Tele2",
      "rate1": 0.205
    },
    {
      "cscd": "Kazakhstan Cellular-Astel",
      "rate1": 0.235
    },
    {
      "cscd": "Kazakhstan Cellular-Tele2",
      "rate1": 0.205
    },
    {
      "cscd": "Kazakhstan Cellular",
      "rate1": 0.215
    },
    {
      "cscd": "Kazakhstan,Leased Net",
      "rate1": 0.805
    },
    {
      "cscd": "Kazakhstan,Leased Net",
      "rate1": 0.805
    },
    {
      "cscd": "Kazakhstan,Leased Net",
      "rate1": 0.805
    },
    {
      "cscd": "Kazakhstan,Leased Net",
      "rate1": 0.805
    },
    {
      "cscd": "Kazakhstan Cellular-Beeline",
      "rate1": 0.215
    },
    {
      "cscd": "Kazakhstan Cellular-K-Cell",
      "rate1": 0.185
    },
    {
      "cscd": "Kazakhstan Cellular-Beeline",
      "rate1": 0.215
    },
    {
      "cscd": "Kazakhstan Cellular-Beeline",
      "rate1": 0.215
    },
    {
      "cscd": "Kazakhstan Cellular-K-Cell",
      "rate1": 0.185
    },
    {
      "cscd": "Kazakhstan Cellular",
      "rate1": 0.215
    },
    {
      "rate1": 0.2,
      "conn_fee": 0
    },
    {
      "rate1": 0.18,
      "conn_fee": 0
    },
    {
      "cscd": "United Arab Emirates",
      "rate1": 0.15
    },
    {
      "cscd": "United Arab Emirates Cellular-Etisalat",
      "rate1": 0.15
    },
    {
      "cscd": "United Arab Emirates Cellular-DU",
      "rate1": 0.15
    },
    {
      "cscd": "United Arab Emirates Cellular-Etisalat",
      "rate1": 0.15
    },
    {
      "cscd": "United Arab Emirates Cellular-DU",
      "rate1": 0.15
    },
    {
      "cscd": "United Arab Emirates Cellular-Etisalat",
      "rate1": 0.15
    },
    {
      "cscd": "United Arab Emirates Cellular-DU",
      "rate1": 0.15
    }
  ] // This variable should be deleted when the API get back to work
  const [isLoading, setIsLoading] = useState(false);
  const [rawPrices, setRawPrices] = useState(
    temporary  // This initial value should be deleted when the API get back to work
  );
  const [country, setCountry] = useState("");
  const basicAuthToken = btoa(`${ResalaUsername}:${ResalaPassword}`);

  const countries = Array.from(new Set(rawPrices?.map((item) => item.cscd))).filter((item) => item);
  const ppm = rawPrices?.find((item) => item.cscd == country)?.rate1;

  const prices = [
    {
      title: "Local Number-MOBILE & LANDLINE",
      icon: icons.Local,
      children: [
        { title: "Voice & SMS", ppm, unit: "month" },
        { title: "Voice", ppm, unit: "month" },
        // { title: "Recieve a call", ppm, unit: "month" },
      ],
      extraText: "Setup fee may apply",
    },
    {
      title: "Toll Free Number",
      icon: icons.TollNumber,
      extraText: "Setup fee may apply",
      children: [{ title: "Toll free Number", ppm, unit: "month" }],
    },
  ];

  const formik = useFormik({
    initialValues,
    validationSchema,
    // onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const getPrices = () => {
    setIsLoading(true);
    const apiUrl = `${ResalaBaseURL}/voice_rate?product_id=238`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${basicAuthToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setRawPrices(data);
      })
      .catch((error) => {
        // Handle errors
        setIsLoading(false);
        toast.error(error, { position: "top-center" });
      });
  };
// Should be uncommented when the API get back to work 
  /* useEffect(() => {
    getPrices();
  }, []); */

  useEffect(() => {
    if (countries?.length) {
      setCountry(countries[0]);
    }
  }, [rawPrices]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NumbersPricingTemplate
        size={size}
        formik={formik}
        formFields={formFields}
        prices={prices}
        countries={countries}
        country={country}
        setCountry={setCountry}
      />
    </>
  );
}
