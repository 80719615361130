import { useState } from "react";
import Box from "@mui/material/Box";
import AppContainer from "components/Atoms/Container/AppContainer";
import { Stack } from "@mui/material";

import { motion } from "framer-motion";
import CustomStepper from "components/Molecules/Stepper/CustomStepper";

export default function VerticalLinearStepper({ steps }) {
  const [activeStep, setActiveStep] = useState(0);

  const handleClick = (index) => {
    setActiveStep(index);
  };

  return (
    <AppContainer
      direction={{ xs: "column", md: "row-reverse" }}
      justifyContent={{ xs: "center", md: "space-between" }}
      alignItems="center"
      sx={{ py: { xs: 0, md: 7 }, pb: { xs: 3, md: 5 }, gap: 3 }}
    >
      <CustomStepper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleClick={handleClick}
        steps={steps}
      />

      <Stack sx={{ width: { xs: "100%", sm: "70%", md: "30%" } }}>
        <motion.div
          animate={{ opacity: [0, 1], x: [-100, 0] }}
          transition={{
            ease: "easeOut",
            duration: 2,
            delay: 0.1,
            type: "spring",
            stiffness: 100,
          }}
          key={activeStep ? activeStep : "empty"}
        >
          <Box
            component="img"
            src={steps[activeStep].img}
            width="100%"
            sx={{ mx: { xs: "auto", md: "0" }, display: "block" }}
          />
        </motion.div>
      </Stack>
    </AppContainer>
  );
}
