import { useThemePalette } from "common/hooks/theme_palette";
import Info from "components/Organisms/about-us/Info";
import Header from "components/Organisms/page-header";
import Section from "components/Organisms/section/Section";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
export default function AboutUsTemplate({ data, size }) {
  const { primary } = useThemePalette();

  return (
    <>
      <Header
        title="Bridging Worlds"
        body="Nurture and expand customer relationships across all channels through the comprehensive RESALA cloud communication platform-as-a-service."
      />
      <Section
        bgcolor={primary}
        title="Welcome to RESALA"
        body="Our mission is to empower organizations for seamless communication and engagement with their customers. With innovation at our core, we're redefining the way businesses connect and thrive in the ever-evolving landscape of customer interaction. Join us on this journey to make every conversation count and embrace the future of engagement. "
        collapse={size <= 900}
        initialLength={120}
      />
      <Info data={data} size={size} collapse={size <= 900} initialLength={120} />
      <NewsLetter/>
    </>
  );
}
