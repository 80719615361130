import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import {motion} from 'framer-motion'
export default function GradientBorderedCard({ title, body, icon, children, cardsx, iconsx ,titleSx,animated}) {
  const { darkGray } = useThemePalette();

  const cardStyle = {
    border: "double 4px transparent",
    borderRadius: "20px",
    backgroundImage:
      "linear-gradient(white, white),radial-gradient(circle at top left, #94f3da, #eaaec8)",
    backgroundOrigin: "border-box",
    backgroundClip: "padding-box, border-box",
    backgroudColor: "white",
    width: { xs: "100%", md: "30%" },
    textAlign: "center",
    py: 1,
    px: 2,
    position: "relative",
    ...cardsx,
  };
  return (
          <Stack sx={cardStyle} component={animated?motion.div:'div'} 
          initial="hidden"
          whileInView="visible"
          // viewport={{ once: true }}
          transition={{ duration: 0.7 }}
          whileHover={{
            scale: 1.2,
            transition: { duration: 0.7 }
          }}
                  variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 }
          }}
  
          >
      {icon && (
        <Box
          component="img"
          src={icon}
          width={{ xs: "50px", md: "76px" }}
          // sx={{ mx: "auto", mt: { xs: "-30%", sm: "", md: "-50%", lg: "-32%", xl: "-25%" } }}
          sx={{
            position: "absolute",
            transformOrigin: "50% ",
            left: { xs: "42%", sm: "45%", md: "35%", lg: "35%", lg: "40%", xl: "42%" },
            top: { xs: "-13%", lg: "-25%" },
            ...iconsx,
          }}
        />
      )}
      <Typography
        
        sx={{
          fontSize: { xs: "18px", sm: "16px" },
          fontWeight: 700,
          fontFamily: "Alef",
          ...titleSx
        }}
        >
        {title}
      </Typography>
      {body && (
        <Typography
          fontSize={{ xs: "16px", sm: "16px" }}
          fontWeight={400}
          fontFamily="Alef"
          color={darkGray}
          sx={{ lineHeight: "27px" }}
        >
          {body}
        </Typography>
      )}
      {children && children}
    </Stack>
  );
}
