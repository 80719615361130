import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import Body from "../Section/Body";
import { motion } from "framer-motion";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Stack>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomTabs({ strategies, collapse }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: { xs: 3, sm: 5 } }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          ScrollButtonComponent={({ direction, onClick }) => null}
          sx={{
            ".MuiTab-root": {
              color: "#727272",
              textTransform: "capitalize",
              fontSize: { xs: "16px", sm: "18px" },
              fontWeight: "400",
              fontFamily: "Alef",
              backgroundColor: "rgba(0, 0, 0, 0.10)",
              borderRadius: "48px",
              px: 3,
              maxWidth: "fit-content",
              mx: "10px",
            },
            ".Mui-selected": {
              background: "linear-gradient(94deg, #68D6BA 7.22%, #EC9EC0 97.75%)",
              textDecoration: "capitalize",
              color: "white !important",
            },
            display: "flex",
            direction: "column",
            justifyContent: "space-between",
          }}
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          {strategies.map((straregy, index) => (
            <Tab key={index} label={straregy.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      <CustomTabPanel
        value={value}
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        backgroundColor="white"
          // justifyContent="space-between"
        gap={5}
      >
        <Stack sx={{ width: { xs: "100%", md: "55%" }, p: 10, ml: 12 }}>
          <Body
            text={strategies[value].content}
            collapse={collapse}
            initialLength={120}
            sx={{
              fontSize: "16px",
              textAlign: { xs: "center", md: "start" },
              lineHeight: { xs: "27px", md: "31px" },
            }}
          />
        </Stack>
        <Stack sx={{ width: { xs: "100%", sm: "80%", md: "40%", lg: "30%" }, p: 5 }}>
          <motion.div
            animate={{ opacity: [0, 1], y: [100, 0] }}
            transition={{
              ease: "easeOut",
              duration: 1.3,
              delay: 0.1,
              type: "spring",
              stiffness: 100,
            }}
            key={value ? value : "empty"}
          >
            <Box component="img" src={strategies[value].image} width="90%" />
          </motion.div>
        </Stack>
      </CustomTabPanel>
    </Box>
  );
}
