import { Box, Stack } from "@mui/system";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";

export default function Info({ data, collapse, initialLength }) {
  const { fadeGray } = useThemePalette();
  return (
    <AppContainer gap={5} py={4}>
      {data.map((item, i) => (
        <Stack
          key={i}
          direction={{
            xs: "column",
            lg: i % 2 === 0 ? "row" : "row-reverse",
          }}
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Stack width={{ xs: "100%", md: "50%" }}>
            <Body
              text={item.info}
              sx={{
                fontFamily: "Alef",
                fontSize: { xs: "16px", md: "20px" },
                color: fadeGray,
                textAlign: { xs: "center", md: "start" },
              }}
              collapse={collapse}
              initialLength={initialLength}
            />
          </Stack>
          <Stack width={{ xs: "80%", md: "30%" }}>
            <Box component="img" src={item.image} width="100%" />
          </Stack>
        </Stack>
      ))}
    </AppContainer>
  );
}
