import { motion,useAnimationControls  } from 'framer-motion';
import { Stack, Box, Typography } from "@mui/material";
import { icons, images } from "assets/AssetHelper";
export const Msg = ({
  title,
  body,
  msgContainer,
  delay,
  duration,
  containerStyle,
  contentPoisiton,  
}) => {

  return (
    <Stack
      sx={{
        position: "absolute",
        scale: { xs: "0.8", sm: "0.8", md: "0.72", lg: "0.9", xl:'1.1' },
        ...containerStyle,
      }}
    >
      <motion.div
        whileInView={{ scale: [0, 1] }}
        transition={{ ease: "easeOut", duration, delay, type: "spring", stiffness: 100 }}
      >
        <Stack sx={{ position: "absolute", ...contentPoisiton }} alignItems="center">
          <Typography color="#447298" fontFamily="Alegreya Sans" fontWeight="700" fontSize="20px">
            {title}
          </Typography>
          <Typography color="#447298" fontFamily="Alegreya Sans" fontWeight="700" fontSize="14px">
            {body}
          </Typography>
        </Stack>
        <Box component="img" src={msgContainer} />
      </motion.div>
    </Stack>
  );
};

export default function EarthModel({ delay, duration }) {  
  
  const controls = useAnimationControls()

  return (
    <Stack sx={{ width: { xs: "100%", sm: "50%", md: "40%", lg: "40%" },mb:8, mt:10}}>
      {/* <Msg
        title="SMS"
        body="Don't miss it! Get 30% Off"
        msgContainer={icons.Msg1}
        delay={delay}
        duration={duration}
        containerStyle={{
          top: { xs: "0%", sm: "3%", md: "2%", lg: "5%", xl: "7%" },
          left: { xs: "-5%", sm: "-5%", md: "5%", lg: "-6%", xl: "0%" },
        }}
        contentPoisiton={{ top: "40%", left: "15%" }}
      />
      <Msg
        title="SMS"
        body="Your order has been confirmed"
        msgContainer={icons.Msg2}
        delay={delay + duration}
        duration={duration}
        containerStyle={{
          top: { xs: "25%", sm: "30%", md: "25%", lg: "25%", xl: "30%" },
          left: { xs: "45%", sm: "45%", md: "43%", lg: "45%", xl: "50%" },
        }}
        contentPoisiton={{ top: "15%", left: "10%" }}
      />
      <Msg
        title="SMS"
        body="Your verification code is 587102"
        msgContainer={icons.Msg3}
        delay={delay + duration * 2}
        duration={duration}
        containerStyle={{
          top: { xs: "60%", sm: "65%", md: "60%", lg: "57%", xl: "65%" },
          left: { xs: "10%", sm: "6%", md: "0%", lg: "18%", xl: "20%" },
        }}
        contentPoisiton={{ top: "15%", left: "10%" }}
      /> */}
      {/* <motion.div ref={constraintsRef} style={{ width: '100%', height: '100%', position: 'relative' }}/>
        <Box
          component="div" // Render as a div instead of an img
          style={{ width: '100%', height: 'auto', cursor: 'grab' }}
        >
          <motion.img
            src={images.Earth} // Replace with your Earth image source
            alt="Earth"
            style={{ width: '100%', height: 'auto' }}
            drag // Enable drag on the img element
            dragElastic={0.2}
            whileHover={{ scale: 1.1 }}
            animate={controls} 
            dragConstraints={constraintsRef}
            dragTransition={{ type: 'spring', stiffness: 300, damping: 20 }}
            onDragEnd={(event, info) => {
              controls.start({ scale: 1.0 }); // Reset scale to 1.0 after drag ends
            }}
            onHoverStart={() => {
              controls.start({ scale: 1.1 }); // Scale up on hover start
            }}
            onHoverEnd={() => {
              controls.start({ scale: 1.0 }); // Scale down on hover end
            }}
              
          />
        </Box> */}
              <Box component="img" src={images.Earth} width="100%" />

    </Stack>
  );
}
