import { Button, Stack } from "@mui/material";
import GradientBtn from "components/Atoms/Buttons/GradientBtn";
import TalkToExpertBtn from "components/Atoms/Buttons/TalkToExpertBtn";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Actions({ sx }) {
  const navigate = useNavigate();
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      gap={{ xs: 3, sm: 4 }}
      justifyContent={{ xs: "center", sm: "start" }}
      sx={sx}
    >
      <GradientBtn
        title="Try Now For Free"
        onClick={(_) => window.open("https://retail.resala.io/")}
      />
      <TalkToExpertBtn
        sx={{
          borderRadius: "46px",
          px: "30px",
          height: 48,
          fontWeight: 600,
          fontSize: "26px",
          whiteSpace: "nowrap",
          fontFamily: "Alumni Sans",
          color: "black",
          border: "2px solid black",
          backgroundColor: "white",
          background: "none",
          "&:hover": {
            border: "2px solid black",
          },
        }}
        variant="outlined"
        title="Contact An Expert"
      />
    </Stack>
  );
}
