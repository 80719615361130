import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {},
  theme_palette: {
    primary: "#C4F6E7",
    secondary: "#E5FBF6",
    darkGray: "#636363",
    textColor: "#3C3C3C",
    fadeGray: "rgba(60, 60, 60, 0.80)",
    fadeBlack: "rgba(0, 0, 0, 0.60)",
    black: "#272727",
    white: "#FFFFFF",
    red:'#DB5F93'
  },
  overrides: {
    MuiPaper: {
      root: {},
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application !
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          p: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
  },
});
