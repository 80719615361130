import { countries } from "countries-list";
import * as Yup from "yup";

export const formFields = [
  { label: "First Name", type: "text", name: "firstName" },
  { label: "Last Name", type: "text", name: "lastName" },
  { label: "Email Address", type: "text", name: "email" },
  {
    label: "Country",
    type: "select",
    name: "country",
    options: Object.values(countries).map((country) => country.name),
  },
  { label: "Company", type: "text", name: "company" },
  { label: "Website", type: "text", name: "website" },
  { label: "Job Role", type: "text", name: "jobRole" },
  { label: "Mobile Phone", type: "phone", name: "phone" },
  { label: "Monthly Contacted Customers", type: "text", name: "monthlyContactedCustomers" },
  { label: "", type: "text", name: "", width: "50%" },
  {
    label: "*Tell us more about your customer communication goals",
    type: "text",
    name: "goals",
    rows: 4,
  },
];

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  company: "",
  website: "",
  jobRole: "",
  phone: "",
  monthlyContactedCustomers: "",
  goals: "",
};

const phoneNumberRegex =
  /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
export const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  country: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  website: Yup.string().required("Required"),
  jobRole: Yup.string().required("Required"),
  phone: Yup.string().matches(phoneNumberRegex, "invalid phone number").required("Required"),
  monthlyContactedCustomers: Yup.number()
    .typeError("Please enter a valid number")
    .integer("Please enter a valid number")
    .positive("Please enter a valid number")
    .required("Required"),
  goals: Yup.string().required("Required"),
});
