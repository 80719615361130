import { Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import Header from "components/Organisms/page-header";
import { useThemePalette } from "common/hooks/theme_palette";
import Section from "components/Organisms/section/Section";
import VirtualNumberService from "components/Organisms/services/did/VirtualNumberService";
import DIDServices from "components/Organisms/services/did/DIDServices";
import ExtraServices from "components/Organisms/services/did/ExtraServices";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
import PerfectNumber from "components/Organisms/services/did/PerfectNumber";

export default function VirtualNumbersTemplate({ features,numbers, extraFeatures, size }) {
  const { primary, white } = useThemePalette();

  return (
    <>
      <Header title="DID Numbers" sx={{ mt: "10px" }} />
      <VirtualNumberService
        title={"Our Virtual Numbers Services"}
        body={
          <div>
            <Typography fontWeight={700} fontSize={"22px"} fontFamily={"Alef"} color={"#636363"}>
              Establish a Local Presence with DID Phone Numbers.
            </Typography>
            <Typography
              sx={{ mt: "10px", width: "678px" }}
              fontWeight={400}
              fontSize={"18px"}
              fontFamily={"Alef"}
            >
              RESALA provides reliable local DID numbers from over 100 countries, removing the need
              for regional offices, fostering trust, and boosting answer rates.
            </Typography>
          </div>
        }
      />
      <PerfectNumber
        title="Select the perfect number for your business"
        collapse={size <= 900}
        initialLength={110}
        numbers={numbers}
      />
      <Section
        title={" DID Numbers Features"}

        bgcolor={primary}
      />
      <DIDServices features={features} />
      <Section
        title={"Why RESALA ?"}
        bgcolor={primary}
      />

      <ExtraServices features={extraFeatures} />
      <NewsLetter />
    </>
  );
}
