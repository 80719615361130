import { Box, Stack, Typography, Grid } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import { motion } from "framer-motion";

export default function UseCases({ data, size }) {
  const { primary } = useThemePalette();

  return (
    <Stack bgcolor={primary}>
      <AppContainer sx={{ py: 5, alignItems: "center", px: { xs: 0, lg: 5 }}}>
        <Grid container spacing={10} justifyContent="center" alignItems="stretch">
          {data.map((elem, i) => (
            <Grid item xs={12} md={3} key={i}  >
              <motion.div
                initial="hidden"
                whileInView="visible"
                // viewport={{ once: true }}
                transition={{ duration: 0.7 }}
                whileHover={{ scale: 1.2 }}
                variants={{
                  visible: { opacity: 1, scale: 1 },
                  hidden: { opacity: 0, scale: 0 },
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent={"stretch"}
                  spacing={3}
                >
                  <Grid item>
                    <Box
                      component="img"
                      src={elem.icon}
                      width={{ xs: "50px", md: "80px" }}
                      sx={{
                        left: { xs: "42%", sm: "45%", md: "35%", lg: "35%", lg: "40%", xl: "42%" },
                        top: { xs: "-13%", lg: "-15%" },
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{
                        fontFamily: "Alef",
                        fontWeight: 700,
                        fontSize: { xs: "14px", sm: "22px" },
                        textAlign: { xs: "center", md: "center" },
                        width: { xs: "200px", md: "263px" },
                      }}
                    >
                      {elem.title}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Body
                      text={elem.body}
                      sx={{
                        fontFamily: "Alef",
                        fontSize: "16px",
                        textAlign: { xs: "start", md: "start" },
                        width: { xs: "200px", md: "263px" },
                      }}
                    />
                  </Grid>
                </Grid>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </AppContainer>
    </Stack>
  );
}
