import { List, ListItem, Stack, Typography } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import GradientBorderedCard from "components/Molecules/Cards/GradientBorderedCard";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import React from "react";

export default function Varieties({ title, body, transactionalSMSVarieties }) {
  return (
    <AppContainer sx={{ py: { xs: 5, md: 10 }, gap: 10 }}>
      <Stack sx={{ textAlign: "center" }} gap={3}>
        <Title text={title} />
        <Body text={body} />
      </Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        sx={{ rowGap: { xs: 5, md: 10 }, columnGap: 5 }}
      >
        {transactionalSMSVarieties.map((item, i) => (
          <GradientBorderedCard key={i} title={item.title} icon={item.icon} cardsx={{ pt: 4 }} animated>
            <List>
              {item.body.map((subItem, i) => (
                <ListItem
                  sx={{
                    textAlign: "center",
                    fontSize: { xs: "16px", sm: "16px" },
                    alignItems: "start",
                    justifyContent: "center",
                    p: 0.5,
                  }}
                  key={i}
                >
                  . {subItem}
                </ListItem>
              ))}
            </List>
          </GradientBorderedCard>
        ))}
      </Stack>
    </AppContainer>
  );
}
