/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  prices: [],
  // prices: [
  //   {
  //     mcc: "226",
  //     mccmnc: "226",
  //     country: "Romania",
  //     network: "All networks",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "226",
  //     mnc: "01",
  //     mccmnc: "226001",
  //     country: "Romania",
  //     network: "Vodafone Romania",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "226",
  //     mnc: "02",
  //     mccmnc: "226002",
  //     country: "Romania",
  //     network: "Romtelecom (CDMA)",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "226",
  //     mnc: "03",
  //     mccmnc: "226003",
  //     country: "Romania",
  //     network: "Cosmote",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "226",
  //     mnc: "04",
  //     mccmnc: "226004",
  //     country: "Romania",
  //     network: "Telemobil-Zapp(CDMA)",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "226",
  //     mnc: "05",
  //     mccmnc: "226005",
  //     country: "Romania",
  //     network: "DIGI Mobil",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "226",
  //     mnc: "10",
  //     mccmnc: "226010",
  //     country: "Romania",
  //     network: "Orange Romania",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "410",
  //     mccmnc: "410",
  //     country: "Pakistan",
  //     network: "All networks",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.28,
  //     prev_rate: 0.28,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "410",
  //     mnc: "01",
  //     mccmnc: "410001",
  //     country: "Pakistan",
  //     network: "Mobilink",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.28,
  //     prev_rate: 0.28,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "410",
  //     mnc: "03",
  //     mccmnc: "410003",
  //     country: "Pakistan",
  //     network: "Ufone",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.28,
  //     prev_rate: 0.28,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "410",
  //     mnc: "04",
  //     mccmnc: "410004",
  //     country: "Pakistan",
  //     network: "ZONG",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.28,
  //     prev_rate: 0.28,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "410",
  //     mnc: "05",
  //     mccmnc: "410005",
  //     country: "Pakistan",
  //     network: "SCOM",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.28,
  //     prev_rate: 0.28,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "410",
  //     mnc: "06",
  //     mccmnc: "410006",
  //     country: "Pakistan",
  //     network: "Telenor Pakistan",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.28,
  //     prev_rate: 0.28,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "410",
  //     mnc: "07",
  //     mccmnc: "410007",
  //     country: "Pakistan",
  //     network: "Warid Telecom",
  //     rate_start_date: "2023.12.13 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.28,
  //     prev_rate: 0.28,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "424",
  //     mccmnc: "424",
  //     country: "United Arab Emirates",
  //     network: "All networks",
  //     rate_start_date: "2023.10.10 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "424",
  //     mnc: "02",
  //     mccmnc: "424002",
  //     country: "United Arab Emirates",
  //     network: "Etisalat",
  //     rate_start_date: "2023.10.10 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  //   {
  //     mcc: "424",
  //     mnc: "03",
  //     mccmnc: "424003",
  //     country: "United Arab Emirates",
  //     network: "du",
  //     rate_start_date: "2023.10.10 00:00:00",
  //     rate_end_date: "2100.01.01 00:00:00",
  //     rate: 0.025,
  //     prev_rate: 0.025,
  //     change_type: "Same",
  //   },
  // ],
  isLoading: {
    type: "",
    loading: false,
  },
};

export default function pricesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_PRICES_SUCCESS: {
      console.log(payload, "prices");
      return {
        ...state,
        prices: payload,
      };
    }
    case Types.PRICING_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
