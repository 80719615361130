import Header from "components/Organisms/page-header";
import Content from "components/Organisms/terms-condition/Content";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
export default function TermsAndConditionsTemplate() {
  return (
    <>
      <Header title="Terms & Conditions" />
      <Content />
      <NewsLetter/>
    </>
  );
}
