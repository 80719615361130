import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useThemePalette } from "common/hooks/theme_palette";

import BasicDatePicker from "../DatePicker/DatePicker";
import dayjs from "dayjs";
import { Box } from "@mui/system";
import { icons } from "assets/AssetHelper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./form.css";
const ErrorMessage = ({ message }) => {
  return <Typography sx={{ color: "red" }}>{message}</Typography>;
};

export default function FormField({ field, formik }) {
  const { darkGray, primary ,secondary } = useThemePalette();
  const { name, label, type, rows } = field;
  const width = { xs: "100%", md: field.width ? field.width : "47%" };
  const { values, handleChange, errors } = formik;

  const error = errors[name];
  const value = values[name];

  const fieldStyle = {
    border: "1px solid #B3B3B3",
    borderRadius: "166px",
    backgroundColor:secondary
  };

  if (type == "text") {
    return (
      <Stack sx={{ width, gap: 1, display: { xs: label ? "flex" : "none", md: "inline-flex" } }}>
        {label && (
          <FormLabel sx={{ color: darkGray, fontFamily: "Alef", fontSize: "18px" }}>
            {label}
          </FormLabel>
        )}
        {label && (
          <Input
            sx={{
              px: rows ? 5 : 3,
              py: 2,
              ...fieldStyle,
            }}
            multiline
            rows={rows}
            name={name}
            disableUnderline={true}
            onChange={handleChange}
            value={value}
            type="number"
          />
        )}
        <ErrorMessage message={error} />
      </Stack>
    );
  }

  if (type == "radio") {
    return (
      <Stack
        sx={{
          width,
          ".MuiFormControlLabel-root": {
            // mt: 0,
          },
        }}
      >
        <FormLabel sx={{ color: darkGray, fontFamily: "Alef", fontSize: "18px" }}>
          {label}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleChange}
          value={value}
        >
          {field.options.map((opt, i) => (
            <FormControlLabel
              name={name}
              key={i}
              value={opt.value}
              control={<Radio />}
              label={opt.label}
              sx={{
                fontFamily: "Alef",
                color: darkGray,
                "& .MuiSvgIcon-root": {
                  width: "32px",
                  height: "32px",
                },
              }}
            />
          ))}
        </RadioGroup>
        <ErrorMessage message={error} />
      </Stack>
    );
  }

  if (type == "select") {
    return (
      <Stack
        sx={{
          width,
          height: "fit-content",
          gap: 1,
        }}
      >
        <FormLabel sx={{ color: darkGray, fontFamily: "Alef", fontSize: "18px" }}>
          {label}
        </FormLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          name={name}
          onChange={handleChange}
          sx={{
            borderRadius: "36px",
            ".MuiSelect-icon": {
              fontSize: "35px",
            },
            backgroundColor:secondary

          }}
          IconComponent={KeyboardArrowDownIcon}
        >
          {field.options?.map((opt, i) => (
            <MenuItem value={opt} key={opt}>
              {opt}
            </MenuItem>
          ))}
        </Select>
        <ErrorMessage message={error} />
      </Stack>
    );
  }

  if (type == "date") {
    return (
      <Stack
        sx={{
          width,
          gap: 1,
          ".MuiOutlinedInput-root": {
            borderRadius: "36px",
            backgroundColor:secondary
          },
          
        }}
      >
        <FormLabel sx={{ color: darkGray, fontFamily: "Alef", fontSize: "18px" }}>
          {label}
        </FormLabel>
        <BasicDatePicker
          value={value ? value : null}
          onChange={(date) => formik.setFieldValue(name, dayjs(date).format("MM/DD/YYYY"))}
          name={name}
          // sx={{backgroundColor:primary}}
        />
        <ErrorMessage message={error} />
      </Stack>
    );
  }

  if (type == "check-box") {
    return (
      <Stack sx={{ width }}>
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              checked={value}
              onChange={(e) => formik.setFieldValue(name, e.target.checked)}
              sx={{ p: 0 }}
            />
          }
          label={label}
          sx={{
            ".MuiFormControlLabel-label": {
              fontSize: "18px",
              color: "#666",
              fontFamily: "Alef",
              fontWeight: 400,
            },
            alignItems: "start",
            gap: 1,
          }}
        />
        <ErrorMessage message={error} />
      </Stack>
    );
  }

  if (type == "file") {
    return (
      <Stack sx={{ width, gap: 1 }}>
        <Typography sx={{ color: darkGray, fontFamily: "Alef", fontSize: "18px" }}>
          {label}
        </Typography>
        <InputLabel htmlFor="file-input" sx={{ p: value.name ? 2 : 3.5, ...fieldStyle }}>
          <Box
            src={icons.Upload}
            component="img"
            sx={{ position: "absolute", right: 15, top: 15 }}
          />
          {value?.name ? value.name : " "}
        </InputLabel>
        <input
          id="file-input"
          type="file"
          accept=".pdf"
          style={{ display: "none" }}
          onChange={(e) => {
            formik.setFieldValue(name, e.target.files[0]);
          }}
          name={name}
        />
        <ErrorMessage message={error} />
      </Stack>
    );
  }

  if (type == "phone") {
    return (
      <Stack sx={{ width, gap: 1 }}>
        <FormLabel sx={{ color: darkGray, fontFamily: "Alef", fontSize: "18px" }}>
          {label}
        </FormLabel>
        <PhoneInput
          country={"us"}
          value={value}
          onChange={(phone) => formik.setFieldValue(name, phone)}
          containerStyle={{ height: "58px", width: "100%" }}
          buttonStyle={{
            borderTopLeftRadius: "36px",
            borderBottomLeftRadius: "36px",
            borderColor: "#B3B3B3",
            padding: "0px 10px",
            backgroundColor: secondary,
          }}
          inputStyle={{
            height: "100%",
            width: "100%",
            borderColor: "#B3B3B3",
            borderRadius: "36px",
            backgroundColor: secondary,
            paddingLeft: "70px",
            fontSize: "18px",
          }}
        />
        <ErrorMessage message={error} />
      </Stack>
    );
  }
}
