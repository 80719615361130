import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import { motion } from "framer-motion";

export default function Security({ title, image, size }) {
  const CustomText = ({ children, sx }) => {
    return (
      <Typography fontFamily="Alef" fontSize="16px" color={fadeGray} sx={sx}>
        {children}
      </Typography>
    );
  };
  const { fadeGray } = useThemePalette();
  return (
    <AppContainer
      sx={{ py: 5 }}
      gap={10}
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack gap={3}>
        <Title text={title} sx={{ textAlign: { xs: "center", md: "start" } }} />
        <Stack sx={{ textAlign: { xs: "center", md: "start" } }}>
          <CustomText>
            . <span style={{ fontWeight: 700 }}>81%</span> of security breaches are due to fragile
            or compromised passwords
          </CustomText>
          <CustomText>
            . <span style={{ fontWeight: 700 }}>61%</span> of individuals employ the same password
            across several accounts
          </CustomText>
        </Stack>
        <Stack>
          <Body
            text="Understanding SMS Two-Factor Authentication SMS two-factor authentication provides a straightforward and  effective means to confirm user identity using additional information exclusive to them."
            sx={{ textAlign: { xs: "center", md: "start" }, fontSize: "16px" }}
            collapse={size <= 900 && true}
            initialLength={100}
          />
        </Stack>
        <Stack gap={3}>
          <CustomText
            sx={{ textAlign: { xs: "center", md: "start" }, fontWeight: 700, color: "#000" }}
          >
            It couples their password with:
          </CustomText>
          <Stack
            direction={{ xs: "column", md: "row" }}
            textAlign={{ xs: "center", md: "start" }}
            gap={5}
          >
            <CustomText>. A unique code dispatched to their device</CustomText>
            <CustomText>. Biometric data</CustomText>
          </Stack>
        </Stack>
      </Stack>

      <Stack sx={{ width: { xs: "100%", md: "50%", lg: "65%", xl: "85%" }, overflow: "hidden" }}>
        <motion.div
          whileInView={{ opacity: [0, 1], x: [100, 0] }}
          transition={{
            ease: "easeOut",
            duration: 2,
            delay: 0.1,
            type: "spring",
            stiffness: 100,
          }}
        >
          <Box component="img" src={image} width="95%" />
        </motion.div>
      </Stack>
    </AppContainer>
  );
}
