import { images } from "assets/AssetHelper";
import { useEffect } from "react";
import NotificationsTemplate from "templates/services/SMS/notifications/Notifications.Template";

export default function NotificationsPage({ size }) {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const reasonsToUseNotifications = [
    {
      title: "Reach Any Customer, Anywhere",
      body: "Resala maintains direct connections with MNOs globally, enabling you to reach customers worldwide and scale your SMS notifications as your business grows.",
      image: images.NotificationsReason1,
    },
    {
      title: "Reliable Medium",
      body: "Available on every mobile device without an internet connection, you can be assured that your vital customer communications are delivered.",
      image: images.NotificationsReason2,
    },
    {
      title: "Stay Compliant",
      body: "Our SMS service is backed up by local support and compliance engines that ensure your SMS notifications consistently adhere to local laws and regulations.",
      image: images.NotificationsReason3,
    },
    {
      title: "Cost-Effective",
      body: "Small and medium-sized businesses can keep engagement high and secure valuable  placement on their customers' devices with SMS notifications, without exceeding their communication strategy budget.",
      image: images.NotificationsReason4,
    },
  ];

  const utilizingNotifications = [
    {
      title: "Banking Reminders",
      body: [
        "SMS alerts for credit card transactions",
        "Confirmation of bank transactions via SMS",
        "Appointment confirmation alerts",
        "Direct deposit activity notifications",
      ],
      image: images.Banking,
      width: { xs: "80%", md: "30%" },
    },
    {
      title: "Retail",
      body: [
        "SMS alerts for flash sales",
        "Pop-up shop or sales notifications via SMS",
        "Purchase confirmation",
        "Updates on delivery status changes",
      ],
      image: images.Retail,
      width: { xs: "100%", md: "35%" },
    },
    {
      title: "Telecommunications",
      body: [
        "Telecommunications",
        "Registration confirmation SMS",
        "Roaming alerts via SMS",
        "Payment confirmation notifications",
        "Appointment confirmation via SMS",
      ],
      image: images.Telecommunications,
      width: { xs: "80%", md: "40%" },
    },
    {
      title: "Travel ",
      body: [
        "Departure changes SMS alerts",
        "Notifications for specific date price changes",
        "Ticket purchase confirmations",
        "Last-minute cancellation alerts",
      ],
      image: images.Travel,
       width: { xs: "100%", md: "45%" },
    },
    {
      title: "Education ",
      body: [
        "Upcoming lecture and event notifications",
        "Tuition payment alerts",
        "Important paperwork submission notifications",
        "Lecture room changes or professor absence alerts",
      ],
      image: images.Education,
      width: { xs: "90%", md: "35%" },
    },
    {
      title: "Healthcare",
      body: [
        "Clinic out-of-office alerts",
        "Insurance charges notifications via SMS",
        "Emergency alerts",
        "Test results notifications via SMS",
      ],
      image: images.HealthCare,
      width: { xs: "100%", md: "35%" },
    },
  ];
  return (
    <NotificationsTemplate
      size={size}
      reasonsToUseNotifications={reasonsToUseNotifications}
      utilizingNotifications={utilizingNotifications}
    />
  );
}
