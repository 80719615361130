import { Box, Stack, Typography,Grid } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import { motion } from "framer-motion";
export default function MessagingServices({ data, size }) {
  return (
    <AppContainer sx={{ py: 5, gap: 5, px: { xs: 0, lg: 15 } }}>
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {data.map((elem, i) => (
          <Grid 
            item 
            xs={12} 
            md={6} 
            key={i}
            sx={{ mb: 3 }}
          >
            <Grid container direction="column" alignItems="center" justifyContent={"center"} spacing={3} >
              <Grid item>
                <Typography sx={{ fontFamily: "Alef", fontWeight: 700, fontSize: { xs: "18px", sm: "16px" } }}>
                  {elem.title}
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  component="img"
                  src={elem.image}
                  width={{ xs: "70%", md: "100%" }}
                  minHeight={'200px'}
                  sx={{ display: "block", mx: "auto" }}
                />
              </Grid>
              <Grid item>
                <Body
                  text={elem.body}
                  sx={{
                    fontFamily: "Alef",
                    fontSize: "16px",
                    textAlign: { xs: "center", md: "center" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </AppContainer>
  );
}
