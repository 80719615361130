import { icons, images } from "assets/AssetHelper";
import Header from "components/Organisms/page-header";
import CommunicationSolutions from "components/Organisms/services/whatsapp/CommunicationSolutions";
import Demo from "components/Organisms/services/whatsapp/Demo";
import KeyFeatures from "components/Organisms/services/whatsapp/KeyFeatures";
import ResalaNumbers from "components/Organisms/services/whatsapp/ResalaNumbers";
import ScheduleDemoForm from "components/Organisms/services/whatsapp/ScheduleDemoForm";
import UseCases from "components/Organisms/services/whatsapp/UseCases";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

function WhatsAppTemplate({
  nums,
  features,
  communicationSolutions,
  useCases,
  formFields,
  formik,
}) {
  const formRef = useRef(null);

  return (
    <>
      <Header title="WhatsApp" icon={icons.Whatsapp2} />
      <ResalaNumbers nums={nums} />
      <KeyFeatures title="WhatsApp Key Features" features={features} />
      <Demo
        title="Craft interactive customer journeys utilizing the WhatsApp Business Platform"
        body="Provide secure interactions, offer continuous customer support, and design distinctive journeys across various industries on the world's most widely used chat app."
        image={images.Conversation}
        btnContent="Contact us for a demo"
        onClick={() => formRef.current?.scrollIntoView({ behavior: "smooth" })}
      />
      <CommunicationSolutions
        title="WhatsApp API Communication Solutions"
        communicationSolutions={communicationSolutions}
      />
      <UseCases title="WhatsApp Use Cases" useCases={useCases} />
      <ScheduleDemoForm
        title="SCHEDULE YOUR DEMO"
        formFields={formFields}
        formik={formik}
        ref={formRef}
      />
    </>
  );
}

export default WhatsAppTemplate;
