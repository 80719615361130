import * as React from "react";
import { Menu, MenuItem, Button, Divider, Box, Stack, ClickAwayListener } from "@mui/material";
import { IconMenuItem, NestedMenuItem } from "mui-nested-menu";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useThemePalette } from "common/hooks/theme_palette";
import { icons } from "assets/AssetHelper";

export default function NestedMenu({
  item,
  handleClick,
  anchorEl,
  open,
  handleClose,
  setActivePath,
  navLinkStyle,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { primary } = useThemePalette();
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(open);
  const isServicesActive = [
    "/sms/promotional",
    "/sms/authentication",
    "/sms/transactional",
    "/sms/reminders",
    "/sms/notifications",
    "/whatsapp",
    "/voice-enterprise",
    "/wholesales",
    "/did"
  ].includes(pathname);



  const isPricingActive = [
    "/pricing/sms",
    "/pricing/voice",
    "/pricing/numbers",
  ].includes(pathname);

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleClose();
  };

  //to remove hover effect from the nestedMenu parent
  const handleClickAway = () => {
    setIsSubMenuOpen(false);
  };

  const menuItemStyle = {
    py: 2,
    backgroundColor: primary,
    fontFamily: "Alef",
    "&:hover": {
      backgroundColor: "rgba(104, 214, 186)",
    },
  };

  React.useEffect(() => {
    (isServicesActive || isPricingActive) && setActivePath(null);
  }, [isServicesActive,isPricingActive]);

  return (
    <>
      <Button sx={item.label=='Services'?navLinkStyle(isServicesActive):navLinkStyle(isPricingActive)} onClick={handleClick}>
        {item?.label} <KeyboardArrowDownRoundedIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiMenu-list": {
            width: "250px", //to change dropdown menu list width
            overflow: "visible !important",
            p: 0,
          },
          ".MuiMenu-paper": {
            borderRadius:"10px",
            ml: 7,
            mt: -1,
            backgroundColor: "transparent",
          },
        }}
      >
        <Box component="img" src={icons.Polygon} sx={{ mb: -1, ml: 2 }} />
        <Stack>
          {item.children?.map((subItem, index) =>
            subItem.type === "normal" ? (
              <React.Fragment key={index}>
                <Divider sx={{ m: "0 !important " }} />
                <IconMenuItem
                  onClick={(_) => handleMenuItemClick(subItem?.path)}
                  leftIcon={<Box component="img" src={subItem?.icon} sx={{ mx: 1 }} />}
                  label={subItem?.label}
                  sx={menuItemStyle}
                />
              </React.Fragment>
            ) : (
              <React.Fragment key={index}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <NestedMenuItem
                    onMouseEnter={() => setIsSubMenuOpen(true)}
                    onMouseLeave={() => setIsSubMenuOpen(false)}

                    label={subItem?.label}
                    parentMenuOpen={open}
                    leftIcon={<Box component="img" src={subItem?.icon} sx={{ mx: 1 }} />}
                    sx={{
                      // Your styling for the NestedMenuItem goes here
                      "&.MuiMenuItem-root": {
                        // Styles for the MenuItem component within NestedMenuItem
                        backgroundColor: isSubMenuOpen ? "rgba(104, 214, 186)" : primary, // Change to your desired background color
                        py: 2,
                        cursor: "pointer",
                      },
                      ".MuiTypography-root": {
                        fontFamily: "Alef !important",
                      },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: 8,
                        horizontal: 0,
                      },

                      PaperProps: {
                        style: {
                          maxHeight: "100%",
                        },
                        
                      },
                    }}
                  >
                    <Stack sx={{ top: -5, backgroundColor: primary ,ml:1 }}>
                      {subItem?.children?.map((elem, i) => (
                        <React.Fragment key={i}>
                          <IconMenuItem
                            onClick={(_) => handleMenuItemClick(elem?.path)}
                            leftIcon={<Box component="img" src={elem?.icon} sx={{ mx: 1 }} />}
                            label={elem?.label}
                            sx={menuItemStyle}
                          />
                          {i < subItem.children.length - 1 && (
                            <Divider sx={{ m: "0 !important " }} />
                          )}
                        </React.Fragment>
                      ))}
                    </Stack>
                  </NestedMenuItem>
                </ClickAwayListener>
              </React.Fragment>
            )
          )}
        </Stack>
      </Menu>
    </>
  );
}
