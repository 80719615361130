import SliderCard from "components/Molecules/Home/SliderCard";
import CustomSlider from "components/Molecules/Custom-slider";
import AppContainer from "components/Atoms/Container/AppContainer";
import { useState } from "react";

export default function Slider({ cards, slidesToShow }) {
  const [activeSlides, setActiveSlides] = useState({
    activeSlide1: 0,
    activeSlide2: 0,
  });

  return (
    <AppContainer sx={{  width: { xs: "100%" } }}>
      <CustomSlider
        header="Why Resala Platform ?"
        slidesToShow={slidesToShow}
        setActiveSlides={setActiveSlides}
      >
        {cards.map((card, i) => (
          <SliderCard
            key={card.id}
            card={card}
            isCentered={i === activeSlides.activeSlide1 || i === activeSlides.activeSlide2}
          />
        ))}
      </CustomSlider>
    </AppContainer>
  );
}
