import { Box, Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import GradientBtn from "components/Atoms/Buttons/GradientBtn";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import { motion } from "framer-motion";

export default function SendSMS({ title, body, btnTitle, handleBtnClick }) {
  const { primary } = useThemePalette();
  return (
    <Stack sx={{ backgroundColor: primary }}>
      <AppContainer
        direction={{ xs: "column-reverse", md: "row" }}
        gap={{ xs: 5, md: 20 }}
        py={5}
        alignItems="center"
      >
        {/* IMAGE */}
        <Stack sx={{ overflow: "hidden" }} width={{ xs: "100%", md: "100%", lg: "90%" }}>
          <motion.div
            whileInView={{ x: [-200, 0] }}
            transition={{
              ease: "easeOut",
              duration: 1,
              delay: 0.1,
              type: "spring",
              stiffness: 100,
            }}
          >
            <Box component="img" src={images.Frame} width="90%" />
          </motion.div>
        </Stack>
        {/* CCONTENT */}
        <Stack gap={{ xs: 3, md: 5 }} py={2}>
          <Title text={title} sx={{ textAlign: { xs: "center", md: "start" } }} />
          <Body text={body} sx={{ textAlign: { xs: "center", md: "start" } }} />
          <GradientBtn
            onClick={handleBtnClick}
            title={btnTitle}
            sx={{ width: "fit-content", px: 8, py: 3, mx: { xs: "auto", md: "0" } }}
          />
        </Stack>
      </AppContainer>
    </Stack>
  );
}
