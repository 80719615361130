import { images, icons } from "assets/AssetHelper";
import { useEffect } from "react";
import RCSTemplate from "templates/services/RCS/RCS.Template";

export default function RCSPage({ size }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const RCSUseCases = [
    {
      title: "Build Customer Trust",
      body: "Enhance response rates with branded and verified business profiles, fostering trust before and during interactions.",
      icon: icons.Shield,
    },
    {
      title: "Impress With Rich Media",
      body: "Showcase your brand with images, audio, video, and action buttons, creating a unique and memorable customer experience.",
      icon: icons.Media,
    },
    {
      title: "Seamless Connectivity",
      body: "Deliver your branded and engaging content straight to your customers' native messaging app without the need for third-party downloads.",
      icon: icons.Connect,
    },

    {
      title: "Guarantee Delivery",
      body: "Ensure your messages are seen with guaranteed delivery, using SMS as a fallback channel for your RCS branded content.",
      icon: icons.Delivery,
    },
  ];
  const features = [
    {
      id: 1,
      label: "Verified Sender",
      title: "Verified Sender",
      icon: icons.Verify,
      content:
        "Customers are more likely to engage meaningfully when they trust your brand. RCS verified sender profiles use visual cues like branding and checkmarks to build and enhance that trust.",
      image: images.Feature1,
    },
    {
      id: 2,
      label: "Custom Branding",
      icon: icons.Custom,
      title: "Custom Branding",
      content:
        "Enhance your brand's identity with impactful visuals. Ensure your messages reflect your brand identity using RCS sender profile features. Showcase your logo, brand name, and colors on screen.",
      image: images.Feature2,
    },
    {
      id: 3,
      label: "Rich Media Cards",
      icon: icons.Cards,
      title: "Rich Media Cards",
      content:
        "Leverage the full potential of RCS rich content capabilities to provide an app-like experience by sharing text, images, GIFs, audio and video files, and documents.",
      image: images.Feature3,
    },
    {
      id: 4,
      label: "Carsoules",
      icon: icons.Carousel,
      title: "Carsoules",
      content:
        "Combine up to 10 rich cards into a single interactive unit for your customers to swipe through. Enjoy an easy and engaging customer experience with scroll, swipe, and tap features.",
      image: images.Feature4,
    },
    {
      id: 5,
      label: "Suggested Actions",
      icon: icons.Action,
      title: "Suggested Actions",
      content:
        "Increase message response rates by enabling customers to reply with a single button within the conversation. Simplify their response process and enhance your messaging results.",
      image: images.Feature5,
    },
  ];
  const featuresData = [
    {
      title: "RCS",
      items: [
        "New protocol that can replace SMS and MMS.",
        "Requires telcos to update their networks.",
        "No character limitations.",
        "Supports rich media and advanced features.",
        "Verified sender ID for enhanced security.",
      ],
    },
    {
      title: "SMS/MMS",
      items: [
        "Operator dependent.",
        "No rich media.",
        "Character count limitations.",
        "One-way communication.",
        "Limited actionability.",
      ],
    },
  ];

  return <RCSTemplate featuresData={featuresData} RCSUseCases={RCSUseCases} features={features} size={size} />;
}
