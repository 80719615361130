import { useEffect } from "react";
import AuthenticationTemplate from "templates/services/SMS/authentication/Authentication.Template";

export default function AuthenticationPage({ size }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const passwordMistakes = [
    "Incorporating personal details",
    "Altering a single character to reset a password",
    "Recurring use of old passwords",
    "Employing a single word",
    "Using fewer than eight characters",
  ];

  const authVarieties = [
    "Multiple-tier authentication",
    "Biometrics",
    "Artificial Intelligence",
    "The Significance of SMS Two-Factor Authentication",
  ];
  return (
    <AuthenticationTemplate
      passwordMistakes={passwordMistakes}
      authVarieties={authVarieties}
      size={size}
    />
  );
}
