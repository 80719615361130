import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";

const Section = ({ title, body, children }) => {
  return (
    <Stack gap={4}>
      <Title text={title} sx={{ fontSize: "18px" , fontFamily:"Alef"}} />
      <Body text={body} sx={{ textAlign: "start", fontSize: "16px", whiteSpace: "pre-line" ,lineHeight:'28px'}} />
      {children}
    </Stack>
  );
};

export default function Content() {
  const { fadeGray } = useThemePalette();
  return (
    <AppContainer gap={4}>
      <Section
        title="Overview"
        body={`These Terms and Conditions (the "Terms" or "Agreement") constitute a legally binding agreement between Resala and yourself. It's crucial that you comprehend your obligations and the restrictions concerning the services you elect to utilize. As our services are varied, additional service-specific terms may come into play. If so, such terms will become integrated into our Agreement.\nWe encourage you to engage with our services responsibly. By accessing our website or employing any of our services, you are accepting our Terms. Please bear in mind that these Terms are subject to modifications over time. If you have engaged with our services in the past, it is not advisable to presume the Terms remain the same. We recommend that you review them regularly for any changes.`}
      />
      <Section
        title="Introduction"
        body={`Your intention is to utilize the services provided by Resala, which comprise the messaging services outlined in these Terms (hereafter referred to as "Service," or "Free Service," collectively the "Services"). You could be either a paying customer ("Customer") or a user of our Free Service without any charge ("Non-Paying Customer"). By completing our online registration form, you are availing of our services as a Non-Paying Customer, as outlined in "The Service and the Free Service 1.1", until Resala decides otherwise.\nResala registered company with its registered office2990 Blackburn street Dallas Texas 75204 U.S.A You express a desire to utilize the Services, and we are agreeable to offering the Services to you, all subject to and in compliance with these Terms.`}
      />
      <Section
        title="Binding Effect"
        body={`By agreeing to these Terms, you are entering a legally binding agreement. Should you accept these Terms on behalf of your employer or another entity, you affirm that you have their express consent to act in their stead. THESE TERMS APPLY TO ALL DOCUMENTS RELATED TO ALL TRANSACTIONS UNDERTAKEN ON RESALA’'S WEBSITE, INCLUDING NOTICES OF CANCELLATION, POLICIES, CONTRACTS, AND APPLICATIONS. In order to access and maintain your electronic records, you may need to acquire certain hardware and software, which is your sole responsibility.\nYou are ineligible to accept these Terms or employ our Services if you are not legally of age to form a binding contract with us or if legal constraints prohibit you from using our services.`}
      />
      <Section
        title="Term and Termination"
        body={`Subject to an alternate arrangement with your account manager, and potential early termination as per these Terms and Conditions, the Agreement extends initially for a 12-month period (the "Initial Term"), starting from the Start Date of utilizing the Service or the Free Service and ending on the corresponding date of the initial service term's expiry. Post the Initial Term, the Agreement continues unless it's canceled via a 30 days' written notice by either party, or as per these Terms and Conditions. \n
         To terminate the Agreement, you must:\n
         . Notify Resala in writing within a 7-working day period starting from the Start Date for immediate effect; or\n. Provide Resala with a minimum of 30 days' written notice, effective no earlier than the End Date. If you wish to terminate prior to the End Date for any reason under these Terms and Conditions, you, as a Customer, must pay Resala the due Customer Charges for each remaining month (or part thereof) of the minimum 12-month term after termination. Prepaid Customer Charges are non-refundable ("No refund, exchange only"), with exceptions made per applicable customer protection laws.
         \n Immediate termination can occur by either party if:\n
         1. The other party becomes insolvent, enters into an arrangement with creditors, goes into liquidation, appoints a receiver or similar official over its assets, is subject to an administrative order, or ceases trading.\n2. The other party commits a material breach of the Agreement and fails to remedy it within 14 days of a written notice requiring its rectification.\n3. Any license necessary for Resala to operate the Services is altered, terminated, or revoked, or if new license requirements prevent Resala from continuing the Services or a Network Operator from enabling Resala to provide the Services.\n4. Resala may terminate the Agreement immediately if any relevant legislation or regulation makes it unviable or impossible for Resala to offer the Services.\n
         If you're utilizing the Free Service, Resala reserves the right to discontinue providing it to you temporarily or permanently without prior notice.\n
         Termination doesn't affect accrued rights and those terms that can survive termination will continue in full force.\n
         Upon termination:\n
         . You must discontinue using the Services.\n. All due amounts under the Agreement must be paid immediately.\n. Any unused credit on your account is forfeited, except for payments received by Resala within seven days before termination.\n. All licenses and rights under these Terms are immediately terminated.
         `}
      />
      <Section
        title="The Service and the Free Service"
        body={`The Service allows a Paying Customer to utilize Our Mobile Messaging Services as outlined during your online registration process. Each such message or action mentioned in these Terms and Conditions will be referred to as a "Chargeable Event."\nThe Free Service allows Non-Paying Customers to:\n
        Utilize the Trial for one or more components of the Service described in the above section "The Service and the Free Service 1.1"; and/or
        Utilize any component of the Service described in the above section "The Service and the Free Service 1.1" or any other service provided by Us free of charge from time to time at Our complete discretion.\nSome or all components of the Free Service can be accessed via a web application, an email gateway, a software tool, and/or a web service as described more specifically on our website at resala.io`}
      />
      <Section
        title="Customer Charges and Payment"
        body={`The following provisions are applicable to Paying Customers only.\nBy accepting these terms, you agree to pay all Customer Charges owed to Us for providing the Service to You and for your access to and usage of the Service. Customer Charges will be paid in advance using the method and terms specified herein.\n
        1.You agree to pre-purchase credits for Chargeable Events for each month of the Agreement or any other agreed-upon period. We will allocate a corresponding number of Chargeable Event credits to You for that period. Each Chargeable Event conducted using the Service will deduct 1 unit charge from your available Chargeable Event credits (subject to any variations or agreements with your account manager).\n2. The Chargeable Event credits purchased by You are valid only for the allocated period. Any additional expenditure of Chargeable Events beyond your allocated limit for that period will not be possible. However, You may purchase new Chargeable Event credits and expand your allocation for the given period at any time.\n3. It is your sole responsibility to ensure that You have sufficient Chargeable Event credits to meet your requirements. You should regularly check your remaining available Chargeable Event credit level on Our website at Resala.io. We shall not be held responsible or liable if You have insufficient Chargeable Event credits to meet your requirements and/or if you have exceeded your Chargeable Event credit allocation(s) for any period.\n4. Please note that a Charge will be incurred for every Chargeable Event conducted by You, regardless of its successful delivery.\n5. Failure to pay any Customer Charges according to the applicable payment terms may result in the suspension of your access to the Service and/or the cessation of any Chargeable Event conducted by You until full payment is received by Us.\n6. You are responsible for the payment of all bank and finance charges. Please ensure that the amount received in Our bank account, after deductions, corresponds to the total amount owed to Us.\n7. Please be aware that payments made are non-refundable ("No refund, exchange only"), except where required by applicable customer protection laws.`}
      />
      <Section
        title="Management of Your Account"
        body={`Once the Free Service session is completed, You will have the option to request the full Service, which will allow You to become a Paying Customer.\n
        Upon using the Free Service under these Terms, a dedicated account manager from Our team will be assigned to You.\n
        The specific terms and conditions of the Service will be provided in written form. In case of any discrepancy between the terms stated here and subsequent terms, the latter will prevail.`}
      />
      <Section
        title="Account, Passwords, and Security"
        body={`To deliver Our services, We may require certain information from You. It is essential that You provide complete and accurate information. If You provide incomplete or inaccurate information, We may suspend or terminate any service. You warrant that all information You provide to Us is complete and accurate, and You indemnify Resala against any liability arising from your failure to provide complete and accurate information. Please promptly notify Us of any changes to your information.\n
        You will be given or asked to choose a user name or customer ID and password for each account. We may inform You about any changes, cancellations, or suspensions of your password.\n
        Security. You agree that:\n
        1. You will keep your username, customer ID, and password confidential.\n2. You will not attempt to bypass Our user authentication systems.\n3. You will immediately report any unauthorized use of your account or any breach of security.\n4. You are fully responsible for all payments and activities that occur on your account.\n5. You are liable for any damage, loss, or costs that We or any third party may incur due to your actions or the actions of a third party using your password, account name, or account information.\n6. You will indemnify Us against any claim resulting from the use of your password, account name, or account information by a third party or from your violation of this section.`}
      />
      <Section
        title="Availability and Interruption of the Service"
        body={`We will make the Services available to You, whether as a Customer or a Non-Paying Customer, with reasonable skill and care. However, You acknowledge that the availability of the Services, your access to and use of the Services, and the successful conduct of any Chargeable Event may depend on factors beyond Our control, such as:\n
        1. Factors affecting the operation of the Services or preventing the successful conduct of Chargeable Events, including geographical or topographical limitations in the network of any telecommunications network operator ("Network Operator"), network capacity, physical obstructions, or atmospheric conditions.\n2. Factors affecting the operation of the Services or preventing the successful conduct of Chargeable Events, including geographical or topographical limitations in the network of any telecommunications network operator ("Network Operator"), network capacity, physical obstructions, or atmospheric conditions.
        Therefore, We cannot guarantee:\n
        1. Continuous availability of the Services, free from faults or interruptions.\n2. Successful delivery of Chargeable Events to intended recipients.\n
        We will not be held liable for any failure to provide the Services due to technical failures or events beyond Our reasonable control, including the actions of Network Operators. We provide all services "as is" and "as available," and We do not warrant, represent, or guarantee, either expressly or implicitly, that any Services are error-free, always available, suitable for any purpose, secure, or free from infringement of any third-party rights.\nWe may, at Our sole discretion, make alterations or improvements to the Services at any time, as long as such alterations do not materially affect the nature or functionality of the Services.\nOccasionally, We may need to suspend the Services provided to You for routine or emergency maintenance and/or repairs. We will make reasonable efforts to provide You with advance notice before any such suspension occurs.\nWe reserve the right, at Our sole discretion, to suspend your access to the Services and/or cease the conduct of any Chargeable Events by You at any time.`}
      />
      <Section title="Support Services">
        <Typography
          sx={{
            textAlign: "start",
            fontSize: "16px",
            color: fadeGray,
          }}
        >
          Unless otherwise agreed in writing, We will provide online technical support for the
          Service and/or Free Service 24/7. You can reach us at the following email address:
          <span style={{ textDecoration: "underline", marginLeft: "5px" }}>support@resala.io</span>
        </Typography>
      </Section>
      <Section
        title="Confidentiality and Data Protection"
        body={`You are required to maintain the confidentiality of any information obtained through the use of Our Services, except for information already in the public domain or information you are legally obligated to disclose. Each party will comply with applicable data protection regulations when processing any personal data, including end-user data. Our full privacy policy can be found on our website at resala.io`}
      />
      <Section
        title="Marketing"
        body={`Either party may use the other party's name and visual identity for marketing and publicity purposes without prior consent, unless otherwise stated in writing. However, if either party objects to such use, written consent must be provided.`}
      />
      <Section
        title="Rules of Use"
        body={`You agree not to use the Services for unauthorized marketing purposes or excessive messaging to end-users. You also agree not to use the Services for improper, fraudulent, immoral, or unlawful purposes. You are responsible for complying with applicable laws, regulations, and codes. We may request information related to your use of the Services, and it is your responsibility to ensure accuracy and completeness. Any complaints received regarding your use of the Service must be addressed and responded to promptly.`}
      />
      <Section
        title="Disclaimers, Limitations of Liability, and Indemnification"
        body={`To the maximum extent permitted by law, Resala shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits, revenues, or data, resulting from your use or inability to use the Services. You agree to indemnify and hold Resala harmless from any claims or expenses arising from your use of the website and/or the Services.`}
      />
      <Section
        title="Intellectual Property"
        body={`All content, trademarks, and data on our website are our property or licensed to us and are protected by domestic and international legislation. We grant you a limited license to use our software or application services, subject to specified terms and conditions. You may not reverse engineer, modify, distribute, or remove proprietary notices without our consent.`}
      />
      <Section
        title="Force Majeure"
        body={`Neither party will be liable for any delay or failure to perform its obligations under this Agreement due to events beyond its reasonable control, including but not limited to telecommunications failures, acts of third parties, or acts of government.`}
      />
      <Section
        title="Assignment"
        body={`No party can assign its rights or obligations under this Agreement without the other party's written consent, except for us, who can assign rights or obligations to affiliated companies.`}
      />
      <Section
        title="Severability"
        body={`If any term or provision of this Agreement is deemed invalid, illegal, or unenforceable, the remaining provisions will remain in full force and effect.`}
      />
      <Section
        title=" Governing Law & Dispute Resolution"
        body={`These Terms are governed by the laws of The United States of America. Any claim arising under this Agreement shall be subject to the non-exclusive jurisdiction of the courts in Texas, USA`}
      />
    </AppContainer>
  );
}
