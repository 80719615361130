import React, { useState } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { icons, images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import SectionBackground from "components/Atoms/Background/SectionBackground";
import { NavLink, useNavigate } from "react-router-dom";

import FadingBorder from "components/Atoms/Border/FadingBorder";
import AppContainer from "components/Atoms/Container/AppContainer";

const socialMedia = [
  { icon: icons.LinkedIn, path: "https://www.linkedin.com/company/resalaio/" },
  { icon: icons.Facebook, path: "https://www.facebook.com/Resala.official" },
  // { icon: icons.Twitter, path: "https://www.facebook.com/Resala.official" },
  {
    icon: icons.Instagram,
    path: "https://instagram.com/resala.official?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr",
  },
];

const companyNavLinks = [
  { label: "Home", path: "/" },
  { label: "Pricing", path: "/pricing" },
  { label: "About Us", path: "/about-us" },
  { label: "Support", path: "/support" },
  { label: "Careers", path: "/careers" },
  { label: "Terms & Conditions", path: "/terms-and-conditions" },
  { label: "Privacy Policy", path: "/privacy-policy" },
];

const servicesNavLinks = [
  { label: "SMS " },
  {
    label: (
      <Box component="span" sx={{ ml: 2 }}>
        Promotional
      </Box>
    ),
    path: "/sms/promotional",
  },
  {
    label: (
      <Box component="span" sx={{ ml: 2 }}>
        Authentication
      </Box>
    ),
    path: "/sms/authentication",
  },
  {
    label: (
      <Box component="span" sx={{ ml: 2 }}>
        Transactional
      </Box>
    ),
    path: "/sms/transactional",
  },
  {
    label: (
      <Box component="span" sx={{ ml: 2 }}>
        Reminders
      </Box>
    ),
    path: "/sms/reminders",
  },
  {
    label: (
      <Box component="span" sx={{ ml: 2 }}>
        Notifications
      </Box>
    ),
    path: "/sms/notifications",
  },
  { label: "Rich Communication Services ", path: "/RCS" },
  { label: "Voice Enterprise", path: "/voice-enterprise" },
  { label: "Virtual Numbers", path: "/virtual-numbers" },
  { label: "Wholesales SMS | Voice ", path: "/wholesales" },
];

//dumb code but to make this one specail case
//
const getConnected = [
  {
    icon: icons.Location,
    info: (
      <>
        USA (HQ) <br /> RESALA INC <br /> 2990 Blackburn Street Office 5147 Dallas Texas <br />
        75204 U.S.A
      </>
    ),
    type: "location",
  },
  { icon: icons.Email, info: "Hello@resala.io", type: "email", path: "mailto:Hello@resala.io" },
];

export default function Footer({ size }) {
  const { darkGray, black } = useThemePalette();
  const navigate = useNavigate();

  // State to track the active button
  const [activeButton, setActiveButton] = useState("");

  const handleButtonClick = (label) => {
    setActiveButton(label); // Update the active button
  };

  const SocialMedia = ({ sx }) => {
    return (
      <Stack direction="row" gap={2} sx={sx}>
        {socialMedia.map((info, index) => (
          <a key={index} href={info?.path} target="_blank" rel="noreferrer">
            <Box component="img" src={info.icon} width={{ xs: "32px", sm: "28px" }} />
          </a>
        ))}
      </Stack>
    );
  };

  const NavTitle = ({ title }) => {
    return (
      <Typography color="#68D6BA" fontFamily="Alegreya Sans" fontWeight={700} fontSize="20px">
        {title}
      </Typography>
    );
  };

  const footerTextStyle = {
    fontFamily: "Alef",
    fontSize: "16px",
    lineHeight: "27px",
    fontWeight: 400,
    ".active": {
      fontWeight: 400,
    },
  };

  return (
    <SectionBackground
      sx={{
        background: `url(${images.WaveBackground}) no-repeat`,
        backgroundSize: "cover",
        py: 2,
      }}
    >
      <AppContainer
        direction={{ xs: "column", md: "row" }}
        gap={3}
        justifyContent="space-between"
        alignItems={{ xs: "center", md: "start" }}
        textAlign={{ xs: "center", md: "start" }}
        sx={{ py: 8 }}
      >
        {/*------------------------- RESALA VISION -------------------------*/}
        <Stack
          sx={{ width: { xs: "100%", md: "23%" } }}
          gap={4}
          textAlign={{ xs: "center", md: "start" }}
          alignItems={{ xs: "center", md: "start" }}
        >
          <Stack>
            <Box
              component="img"
              src={images.Logo}
              width={{
                xs: "158px",
                sm: "186px",
              }}
            />
          </Stack>
          <Stack>
            <Typography sx={footerTextStyle}>
              Sign up for Resala for free! Send your first text, make a call, or get a DID number.
              Upgrade anytime to our pay-as-you-go plan and scale your communication effortlessly.
            </Typography>
          </Stack>
          <SocialMedia sx={{ display: { xs: "none", md: "flex" } }} />
        </Stack>
        {/*------------------------- NAV -------------------------*/}
        <Stack sx={{}} gap={1}>
          <NavTitle title="Company" />
          <Stack sx={footerTextStyle} gap={1}>
            {companyNavLinks.map((nav, i) => (
              <NavLink
                key={i}
                to={nav.path}
                style={{
                  fontWeight: activeButton === nav.label ? 700 : 400, // Apply bold to the active button
                  textDecoration: "none",
                }}
                onClick={() => handleButtonClick(nav.label)} // Set active button on click
              >
                {nav.label}
              </NavLink>
            ))}
          </Stack>
        </Stack>
        {/*------------------------- SERVICES -------------------------*/}
        <Stack sx={{}} gap={2}>
          <NavTitle title="Services" />
          <Stack sx={footerTextStyle} gap={1}>
            {servicesNavLinks.map((nav, i) => (
              <NavLink
                key={i}
                to={nav.path}
                style={{
                  fontWeight: activeButton === nav.label ? 700 : 400, // Apply bold to the active button
                  textDecoration: "none",
                }}
                onClick={() => handleButtonClick(nav.label)} // Set active button on click
              >
                {nav.label}
              </NavLink>
            ))}
          </Stack>
        </Stack>
        {/*------------------------- GET CONNECTED -------------------------*/}
        <Stack sx={{ width: { xs: "100%", md: "25%" } }} gap={2}>
          <NavTitle title="Get Connected" />
          <Stack gap={3} alignItems={{ xs: "center", md: "start" }}>
            {getConnected.map((item, i) => (
              <Stack
                key={i}
                direction="row"
                alignItems={{ xs: "center", md: "start" }}
                gap="24px"
                sx={{ position: "relative" }}
              >
                <Box
                  component="img"
                  src={item.icon}
                  width="24px"
                  sx={{
                    position: { xs: item.type === "location" && "absolute", md: "static" },
                    left: "25%",
                    top: "0%",
                  }}
                />

                {item.type === "email" ? (
                  <Button
                    sx={{
                      ...footerTextStyle,
                      textDecoration: item.type == "email" && "underline",
                      lineHeight: "29px",
                      p: 0,
                      color: "black",
                    }}
                    href={item?.path}
                    target="_blank"
                  >
                    {item.info}
                  </Button>
                ) : (
                  <Typography
                    sx={{
                      ...footerTextStyle,
                      textDecoration: item.type == "email" && "underline",
                      lineHeight: "29px",
                    }}
                    href={item?.path}
                    target="_blank"
                  >
                    {item.info}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>
        </Stack>
        <SocialMedia sx={{ display: { xs: "flex", md: "none" } }} />
      </AppContainer>
      <FadingBorder />
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{ py: 2 }}
        justifyContent="center"
        alignItems="center"
        gap={{ xs: 3, md: 0 }}
      >
        <Stack gap={1} direction="row" justifyContent="center">
          <Box component="img" src={icons.CopyRight} width="16px" />
          <Typography
            sx={{
              fontFamily: "Alef",
              fontSize: { xs: "16px", sm: "16px" },
              color: black,
              fontWeight: 400,
            }}
          >
            2023 , All Rights Reserved.
          </Typography>
        </Stack>
      </Stack>
    </SectionBackground>
  );
}
