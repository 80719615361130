import { Grid, Box, Typography, Stack } from "@mui/material";
import * as React from "react";

import Title from "components/Molecules/Section/Title";
import AppContainer from "components/Atoms/Container/AppContainer";
import CustomAccordion from "components/Atoms/Accordion/CustomAccordion";
import { useThemePalette } from "common/hooks/theme_palette";

export default function FAQ({ title, FAQQuestions }) {
  const { darkGray, primary } = useThemePalette();

  return (
    <>
     <Stack bgcolor={primary}>
        <Title
          text={title}
          sx={{
            textAlign: "center",
            m: 4,
          }}
        />
      </Stack>
      <AppContainer sx={{ py: 4, alignItems: "center" }}>
        {/* FAQ START */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {FAQQuestions.slice(0, Math.ceil(FAQQuestions.length / 2)).map((Q, i) => (
              <Box key={i} mb={2}>
                <CustomAccordion
                  title={Q.title}
                  body={
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontFamily: "Alef",
                        mt: "10px",
                      }}
                    >
                      {Q.body}
                    </Typography>
                  }
                />
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            {FAQQuestions.slice(Math.ceil(FAQQuestions.length / 2)).map((Q, i) => (
              <Box key={i} mb={2}>
                <CustomAccordion
                  title={Q.title}
                  body={
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontFamily: "Alef",
                        mt: "10px",
                      }}
                    >
                      {Q.body}
                    </Typography>
                  }
                />
              </Box>
            ))}
          </Grid>
        </Grid>
        {/* FAQ END */}
      </AppContainer>
    </>
  );
}
