import { useEffect, useState } from "react";
import PricingTemplate from "templates/pricing/sms/SMSPricing.Template";
import { useFormik } from "formik";
import { formFields, initialValues, validationSchema } from "./form";
import Loader from "components/Atoms/Loader/Loader";
import { toast } from "react-toastify";
import {
  ResalaUsername,
  ResalaPassword,
  EmailJSPublicKey,
  EmailJSServiceID,
  ResalaBaseURL,
} from "services/modules/utils/constant";
import emailjs from "emailjs-com";

export default function SMSPricingPage({ size }) {
  const [isLoading, setIsLoading] = useState(false);
  const [prices, setPrices] = useState();
  const basicAuthToken = btoa(`${ResalaUsername}:${ResalaPassword}`);

  const sendEmail = (values) => {
    setIsLoading(true);
    emailjs.send(EmailJSServiceID, "download_prices", values, EmailJSPublicKey).then(
      (result) => {
        setIsLoading(false);
        toast.success("form sent", { position: "top-center" });
      },
      (error) => {
        setIsLoading(false);
        toast.error("form failed", { position: "top-center" });
      }
    );
  };

  const getPrices = () => {
    setIsLoading(true);
    const apiUrl = `${ResalaBaseURL}/sms_rate?product_id=96`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${basicAuthToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setPrices(data);
      })
      .catch((error) => {
        // Handle errors
        setIsLoading(false);
        toast.error(error, { position: "top-center" });
      });
  };

  const downloadFile = async (url) => {
    // Replace 'your_excel_file_url' with the actual URL of the Excel file
    const excelFileUrl = url;

    try {
      // Make a fetch request to the Excel file URL
      const response = await fetch(excelFileUrl);

      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a Blob
        const blob = await response.blob();

        // Create a download link
        const downloadLink = document.createElement("a");

        // Create a URL for the Blob and set it as the link's href
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;

        // Set the download attribute with the desired file name
        downloadLink.download = "example.xlsx";

        // Append the link to the document
        document.body.appendChild(downloadLink);

        // Trigger a click event on the link to start the download
        downloadLink.click();

        // Remove the link from the document
        document.body.removeChild(downloadLink);
      } else {
        // Handle errors if the request was not successful
        console.error(`Failed to download Excel file. Status: ${response.status}`);
      }
    } catch (error) {
      // Handle other errors
      console.error("Error:", error);
    }
  };

  const getDownloadURL = (data) => {
    const { file_id, file_name } = data;
    const apiUrl = `${ResalaBaseURL}download/${file_id}/${file_name}`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${basicAuthToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response;
      })
      .then((data) => {
        // Handle the data from the successful response
        console.log(data?.url);
        downloadFile(data?.url);
      })
      .catch((error) => {
        // Handle errors
        toast.error(error, { position: "top-center" });
      });
  };

  const getFileData = () => {
    const apiUrl = `${ResalaBaseURL}/export_sms_rate?product_id=96`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${basicAuthToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        getDownloadURL(data);
      })
      .catch((error) => {
        // Handle errors
        toast.error(error, { position: "top-center" });
      });
  };

  const handleSubmit = (values, { resetForm }) => {
    sendEmail(values);
    getFileData();
    resetForm();
  };

  useEffect(() => {
    getPrices();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Loader open={isLoading} />
      <PricingTemplate size={size} formik={formik} formFields={formFields} prices={prices} />
    </>
  );
}
