import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Title from "components/Molecules/Section/Title";
import GradientBorderedCard from "components/Molecules/Cards/GradientBorderedCard";

export default function UseCases({ title, useCases }) {
  const { primary, darkGray } = useThemePalette();

  return (
    <Stack gap={10}>
      {/* ----------------- CARDS ----------------- */}
      <Stack sx={{ backgroundColor: primary, py: 1 }}>
        <AppContainer gap={5}>
          <Title text={title} sx={{ mx: "auto" }} />
          <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" gap={5}>
            {useCases.slice(0, 3).map((item) => (
              <GradientBorderedCard title={item.title} body={item.body} key={item.id} animated/>
            ))}
          </Stack>
        </AppContainer>
      </Stack>
      <Stack sx={{ backgroundColor: primary, py: 2 }}>
        <AppContainer gap={5}>
          {useCases.slice(3).map((item) => (
            <Stack
              key={item.id}
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              gap={3}
            >
              <Box component="img" src={item.img.src} width={{ xs: "80px", sm: "80px" }} />
              <Stack gap={1} sx={{ textAlign: { xs: "center", md: "start" } }}>
                <Typography
                  fontSize={{ xs: "18px", sm: "16px" }}
                  fontWeight={700}
                  fontFamily="Alef"
                >
                  {item.title}
                </Typography>
                <Typography
                  fontSize={{ xs: "16px", sm: "16px" }}
                  fontWeight={400}
                  fontFamily="Alef"
                  color={darkGray}
                >
                  {item.body}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </AppContainer>
      </Stack>
    </Stack>
  );
}
