import Header from "components/Organisms/page-header";
import Content from "components/Organisms/privacy-policy/Content";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
export default function PrivacyPolicyTemplate() {
  return (
    <>
      <Header title="Privacy Policy" />
      <Content />
      <NewsLetter />      
    </>
  );
}
