import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import { useMotionValue, useTransform, motion, animate } from "framer-motion";
import { useEffect } from "react";

export default function ResalaNumbers({ nums }) {
  const { primary } = useThemePalette();

  const NumberCom = ({ number }) => {
    const { value, type, text } = number;
    const count = useMotionValue(0);
    const rounded = useTransform(count, (latest) => {
      if (Number.isInteger(value)) {
        return Math.round(latest);
      } else {
        return Number.parseFloat(latest).toFixed(2);
      }
    });

    useEffect(() => {
      const controls = animate(count, value, { duration: 3 });
      return controls.stop;
    }, []);

    return (
      <Stack
        sx={{
          textAlign: "center",
          width: { xs: "100%", md: "25%" },
          height: "100%",
          // mx: "auto",
        }}
      >
        <Stack direction="row" sx={{ fontSize: "60px", justifyContent: "center", mb: "auto" }}>
          <motion.span style={{ fontFamily: "Alumni Sans" }}>{rounded}</motion.span>
          <span>{type == "percentage" && "%"}</span>
        </Stack>
        <Stack sx={{ mb: "auto", mx: "auto" }}>
          <Typography fontFamily="Alef" sx={{ color: "rgba(0, 0, 0, 0.60)" }}>
            {text}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack sx={{ bgcolor: primary, py: 4 }}>
      <AppContainer
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        {nums.map((num) => (
          <NumberCom key={num.id} number={num} />
        ))}
      </AppContainer>
    </Stack>
  );
}
