import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import Body from "../Section/Body";
import { motion } from "framer-motion";
import TalkToExpertBtn from "components/Atoms/Buttons/TalkToExpertBtn";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        backgroundColor: "white",
        px: { xs: 2, sm: 20 },
        borderRadius: 2,
        margin: 0,
      }}
    >
      {children}
    </Stack>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FeatureTabs({ features, collapse }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ margin: 0 }}>
      <Box sx={{ display: "flex", justifyContent: "center", mb: { xs: 3, sm: 5 } }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          ScrollButtonComponent={({ direction, onClick }) => null}
          sx={{
            ".MuiTab-root": {
              color: "#727272",
              textTransform: "capitalize",
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              fontWeight: "400",
              fontFamily: "Alef",
              backgroundColor: "rgba(0, 0, 0, 0.10)",
              borderRadius: "48px",
              px: 3,
              maxWidth: "fit-content",
              mx: "5px",
            },
            ".Mui-selected": {
              background: "linear-gradient(94deg, #68D6BA 7.22%, #EC9EC0 97.75%)",
              textDecoration: "capitalize",
              color: "white !important",
            },
            display: "flex",
            direction: "column",
            justifyContent: "space-between",
          }}
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          {features.map((feature, index) => (
            <Tab key={index} label={feature.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>

      <CustomTabPanel value={value} sx={{ p: { xs: 2, sm: 5 }, mt: 5 }}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
          sx={{ gap: { xs: 3, sm: 5 }, mb: 3 }}
        >
          <Stack
            sx={{
              width: { xs: "100%", sm: "80%", md: "40%", lg: "26%" },
              backgroundColor: "white",
              borderRadius: 2,
              margin: 0,
              mt: { xs: 0, md: 5 },
              p: 1,
            }}
          >
            <motion.div
              animate={{ opacity: [0, 1], y: [100, 0] }}
              transition={{
                ease: "easeOut",
                duration: 1.3,
                delay: 0.1,
                type: "spring",
                stiffness: 100,
              }}
              key={value ? value : "empty"}
              style={{ display: "flex" }}
            >
              <Box
                component="img"
                src={features[value].image}
                sx={{
                  mx: "auto",
                  width: { xs:"250px", md:"300px" },
                }}
              />
            </motion.div>
          </Stack>
          <Stack
            sx={{
              width: { xs: "100%", md: "65%" },
              backgroundColor: "white",
              borderRadius: 2,
              gap: { xs: "16px", md: "20px" },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                display: "flex",
                alignItems: "center",
                color: "#68D6BA",
                fontFamily: "Alumni Sans",
                fontSize: { xs: "22px", md: "26px" },
                fontWeight: 700,
                textAlign: { xs: "center", md: "left" }, // Center-align text on small screens
              }}
            >
              <Box component="img" src={features[value].icon} sx={{ marginRight: 1 }} />
              <span>{features[value].title}</span>
            </Typography>
            <Body
              text={features[value].content}
              collapse={collapse}
              initialLength={120}
              sx={{
                fontSize: "16px",
                textAlign: { xs: "center", md: "start" },
                lineHeight: { xs: "27px", md: "31px" },
              }}
            />
            <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "start" } }}>
              <TalkToExpertBtn
                sx={{ fontSize: "20px", borderRadius: "48px", width: "fit-content", px: 5 }}
              />
            </Box>
          </Stack>
        </Stack>
      </CustomTabPanel>
    </Box>
  );
}