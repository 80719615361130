import Header from "components/Organisms/page-header";
import ContactSupportForm from "components/Organisms/support/ContactSupportForm";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
export default function SupportTemplate({ formFields, formik }) {
  return (
    <>
      <Header title="Contact Support" />
      <ContactSupportForm
        title="Need a support?"
        body="Facing a problem? We are here for you!"
        formik={formik}
        formFields={formFields}
      />
            <NewsLetter />      

    </>
  );
}
