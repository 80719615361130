import { Stack } from "@mui/material";
import { useNavigate } from "react-router";
import { useThemePalette } from "common/hooks/theme_palette";

import Title from "../Section/Title";
import AppContainer from "components/Atoms/Container/AppContainer";
import GradientBtn from "components/Atoms/Buttons/GradientBtn";
import TalkToExpertBtn from "components/Atoms/Buttons/TalkToExpertBtn";

export default function NewsLetter({text,gradientText,text2}) {
  const { primary } = useThemePalette();
  const navigate = useNavigate();
  return (
    <Stack bgcolor={primary}>
      {/* <AppContainer
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        sx={{ py: 5 }}
        gap={4}
      >
        <Stack
          alignItems={{ xs: "center", md: "start" }}
          gap={4}
          sx={{ width: { xs: "100%", md: "40%", lg: "40%" } }}
        >
          <Title
            text="Subscribe to"
            gradientText="Our Newsletter"
            
          />
          <Body
            text="Every now and then, we like to share our handy insights, information and events. stay connected with us."
            sx={{ textAlign: { xs: "center", md: "start" } }}
          />
        </Stack>
        <Stack justifyContent="center" width={{ xs: "100%", md: "50%" }}>
          <Stack
            sx={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "36px",
            }}
            direction="row"
          >
            <Input
              sx={{
                width: "100%",
                py: 1,
                px: 3,
                ".Mui-focused": {
                  height: "15px",
                  color: "red",
                },
                border: "1px solid #B3B3B3",
                borderRadius: "36px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRight: "none",
              }}
              disableUnderline={true}
            />
            <GradientBtn
              title="subscribe"
              sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                height: "100%",
                minWidth: "30%",
              }}
            />
          </Stack>
        </Stack>
      </AppContainer> */}
      <AppContainer alignItems="center" sx={{ gap: 2, py: 4 }}>
        <Title text={text?text:"Get started with"} gradientText={gradientText?gradientText:"RESALA"} text2={text2?text2:"today"} sx={{textAlign:"center"}}/>
        <TalkToExpertBtn />
      </AppContainer>
    </Stack>
  );
}
