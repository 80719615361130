import { images } from "assets/AssetHelper";
import { useEffect } from "react";
import RemindersTemplate from "templates/services/SMS/reminders/Reminders.Template";

export default function RemindersPage({ size }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const remindersSectors = [
    {
      title: "Healthcare Reminders",
      body: [
        "Medical appointments",
        "Medication reminders",
        "Billing reminders",
        "Mitigate missed appointments",
        "Dispatch text reminders for medical appointments to decrease the incidence of no-shows and rescheduling.",
      ],
      image: images.HealthCare,
      width: { xs: "100%", md: "25%", lg: "30%" },
    },
    {
      title: "Retail Reminders",
      body: [
        "Abandoned carts",
        "Delivery reminders",
        "Restock reminders",
        "Enhance conversion rates and augment sales",
        "Prompt customers to finalize their purchase with a reminder text about products left in their cart, motivating them to complete the transaction quickly before items are removed.",
      ],
      image: images.Retail,
      width: { xs: "100%", md: "30%" },
    },
    {
      title: "Travel Reminders",
      body: [
        "Check-in reminders",
        "Travel detail reminders",
        "Travel criteria reminders",
        "Reduce airport waiting times and boost online engagement",
        "Remind busy travelers to check into their flight online and avoid long airport queues, streamlining the check-in process and ensuring more travelers utilize this feature on your website. ",
      ],
      image: images.Travel,
      width: { xs: "100%", md: "40%" },
    },
    {
      title: "Banking Reminders",
      body: [
        "Overdue payment reminders",
        "Insurance renewal reminders",
        "Card expiration reminders",
        "Loan repayment reminders",
        "Minimize interest accumulation on bills",
        "If a client is late with a payment, send them a text reminder to decrease the interest accumulating on their principal amount. These reminders can initiate a conversation and enhance customer satisfaction with your bank, whether they simply overlooked a payment or require a better payment plan.",
      ],
      image: images.Banking,
      width: { xs: "60%", md: "25%" },
    },
    {
      title: "Telecommunications Reminders",
      body: [
        "Payment reminders",
        "Top-up reminders",
        "Contract renewal reminders",
        "Boost prompt payment rates",
        "Even though customers regularly receive their telecom bills, these can get overlooked amid their monthly payments. A brief text reminder can help customers remember to pay their balance on time and evade late fees.",
      ],
      image: images.Telecommunications,
      width: { xs: "100%", md: "35%" },
    },
  ];
  const advantageOfReminders = [
    {
      id: 1,
      label: "Easy to Automate",
      description:
        "Text message reminders are simple to create. Depending on your preference, use no-code or low-code APIs to generate text reminders that your customers are sure to see.",
    },
    {
      id: 2,
      label: "Re-engaging Customers",
      description:
        "Text reminders are an excellent way to re-engage customers. Regardless of the stage in the customer journey, you can keep your customers updated by reminding them about your services, appointments, deadlines, and any other prompts that will encourage them to engage with your brand.",
    },
    {
      id: 3,
      label: "Fostering Customer Loyalty",
      description:
        "Demonstrate your care for your customers by sending meaningful reminders at the perfect moment. When customers know they can trust your brand, their loyalty grows. Building your brand persona and reputation has never been easier with reliable and dependable text message reminders.",
    },
    {
      id: 4,
      label: "Easy Integration",
      description:
        "Integrate our text message service into any existing communication stack instantly. You can begin creating and sending text message reminders on a single platform, with 24/7 integration support to ensure your customers never miss a reminder.",
    },
  ];
  return (
    <RemindersTemplate
      remindersSectors={remindersSectors}
      advantageOfReminders={advantageOfReminders}
      size={size}
    />
  );
}
