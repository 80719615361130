import { Stack } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import CustomTabs from "components/Molecules/Tabs/CustomTabs";
import React from "react";

export default function Strategies({ title, body, collapse, initialLength, strategies }) {
  const { primary } = useThemePalette();
  return (
    <Stack bgcolor={primary} mb={{ xs: 5, md: 10 }}>
      <AppContainer gap={3} py={3}>
        <Title text={title} sx={{ mx: "auto", textAlign: "center" }} />
        <Body text={body} collapse={collapse} initialLength={initialLength} />
      </AppContainer>
        <CustomTabs strategies={strategies} collapse={collapse} />
    </Stack>
  );
}
