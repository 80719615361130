import { Stack, Typography } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import GradientBorderedCard from "components/Molecules/Cards/GradientBorderedCard";
import { useThemePalette } from "common/hooks/theme_palette";

export default function DIDServices({ features }) {
  const { primary } = useThemePalette();

  return (
    <AppContainer sx={{ py: 5, pb: 10 }} gap={7}>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        sx={{ rowGap: "32px", columnGap: "25px" }}
      >
        {features.map((item, i) => (
          <GradientBorderedCard
            key={i}
            cardsx={{
              alignItems: "flex-start",
              width: { xs: "100%", md: "25%" },
              gap: 2,
              display: "flex",
              flexDirection: "column",
            }}
            animated
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <img src={item.icon} alt={item.title} style={{ width: '40px', height: 'auto' }} />
              <Typography fontFamily="Alef"  textAlign="start" alignItems="flex-start" fontSize={{ xs: "18px", md: "18px" }} fontWeight={700}>
                {item.header}
              </Typography>
            </Stack>
            <Typography
              fontFamily="Alef"
              fontSize={{ xs: "16px", md: "16px" }}
              textAlign="start"
              width="100%"
              mb={3}
            >
              {item.info}
            </Typography>
          </GradientBorderedCard>
        ))}
      </Stack>
    </AppContainer>
  );
}
