import { Stack, Box, Typography } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import Title from "components/Molecules/Section/Title";
import GradientBorderedCard from "components/Molecules/Cards/GradientBorderedCard";

export default function ExtraServices({ features }) {
  return (
    <AppContainer sx={{ py: 5, pb: 10 }} gap={7}>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        sx={{ rowGap: {md:10,xs:3}, columnGap: 5 }}
      >
        {features.map((item, i) => (
          <GradientBorderedCard
            key={i}
            title={item.title}
            icon={item.icon}
            // cardsx={{ pt: 6, width: { xs: "100%", md: "25%" }, py: 2, px: 0.9, gap: 2 ,pb:5 , flexDirection:"row", alignItems:"center"}}
            cardsx={{ flexDirection:"row", alignItems:"center",py:3,px:3,gap:1,width: { xs:"100%",md: "25%", lg:"25%" },alignContents:"center"}}
            animated
            iconsx={{ left: { xs: "0%", sm: "0%", md: "0%", lg: "0%", xl: "0%" },top: { xs: "6%", lg: "6%" },width:{ xs: "70px",md:"45px",lg: "76px" },position:"absoulte"}}
          >
            <Typography fontFamily="Alef" fontSize={{ xs: "15px", md: "11px",lg:"17px" }} textAlign={"start"}fontWeight={700}>
              {item.header}
            </Typography>
          </GradientBorderedCard>
        ))}
      </Stack>
    </AppContainer>
  );
}
