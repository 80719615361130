import { countries } from "countries-list";
import * as Yup from "yup";

export const formFields = [
  { label: "First Name", type: "text", name: "firstName" },
  { label: "Last Name", type: "text", name: "lastName" },
  { label: "Email Address", type: "text", name: "email" },
  {
    label: "Country",
    type: "select",
    name: "country",
    options: Object.values(countries).map((country) => country.name),
  },
  { label: "API Key", type: "text", name: "APIKey" },
  { label: "Mobile Phone", type: "phone", name: "phone" },
  // { label: "Birthday", type: "date", name: "birthday" },
  {
    label: "*Tell us about the issue you are facing",
    type: "text",
    name: "issues",
    rows: 4,
  },
];

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  APIKey: "",
  phone: "",
  birthday: null,
  issues: "",
};

const phoneNumberRegex =
  /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
export const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  country: Yup.string().required("Required"),
  APIKey: Yup.string().required("Required"),
  phone: Yup.string().matches(phoneNumberRegex, "invalid phone number").required("Required"),
  issues: Yup.string().required("Required"),
});
