import { images,icons } from "assets/AssetHelper";
import { useEffect } from "react";
import WholeSalesTemplate from "templates/services/wholesales/WholeSales.Template";

export default function WholeSalesPage({ size }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const wholeSalesServices = [
    {
      title: "A2P Messaging",
      body: "Our A2P messaging services are crafted to help operators increase revenue while adhering to regulatory standards, using state-of-the-art technology to ensure secure message delivery.",
      image: images.A2P,
    },
    {
      title: "Text-to-Speech",
      body: "Leverage advanced text-to-speech technology to automate scripted calls with real-time voice messages, eliminating the need for professional recording services and ensuring consistent communication.",
      image: images.P2P,
    },

  ];


  const resalaReasons = [
    {
      title: "Global Reach",
      body: "With connections in 200 countries and numerous telecom operator partnerships, we have the global expertise to meet your needs",
    },
    {
      title: "Cutting-Edge Technology",
      body: "We leverage the latest technologies to provide the highest levels of service, security, and support.",
    },
    {
      title: "Dedicated Support",
      body: "Our support team is committed to delivering exceptional service and assistance, ensuring smooth operation.",
    },
    {
      title: "Regulatory Compliance",
      body: "We navigate the complexities of regulated markets, ensuringall services comply with local regulations.",
    },
  ];



  return <WholeSalesTemplate wholeSalesServices={wholeSalesServices} resalaReasons={resalaReasons}  size={size} />;
}
