import WhatsAppTemplate from "templates/services/whatsapp/WhatsApp.Template";
import { icons, images } from "assets/AssetHelper";
import { formFields, initialValues, validationSchema } from "./form";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Loader from "components/Atoms/Loader/Loader";
import { toast } from "react-toastify";
import emailjs from "emailjs-com";

export default function WhatsAppPage() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nums = [
    {
      id: 1,
      text: (
        <>
          billion users are <br />
          active on WhatsApp.
        </>
      ),
      value: 2.44,
      type: "normal",
    },
    {
      id: 2,
      text: (
        <>
          of users feel that WhatsApp helps <br /> businesses connect with them personally.
        </>
      ),
      value: 68,
      type: "percentage",
    },
    {
      id: 3,
      text: (
        <>
          Feel more confident messaging <br />
          businesses than sending emails.
        </>
      ),
      value: 66,
      type: "percentage",
    },
    {
      id: 4,
      text: (
        <>
          Believe that WhatsApp is the <br />
          easiest way to contact a business.
        </>
      ),
      value: 70,
      type: "percentage",
    },
  ];

  const features = [
    {
      id: 1,
      icon: icons.Keyfeature1,
      header: (
        <>
          Effortless Notification <br /> Management
        </>
      ),
      info: (
        <>
          Seamlessly send business-initiated and templated messages anytime, simplifying
          communication.
        </>
      ),
    },
    {
      id: 2,
      icon: icons.Keyfeature2,
      header: (
        <>
          Enhanced Customer <br /> Care Automation
        </>
      ),
      info: (
        <>
          Elevate your brand reputation with top-notch customer service automation, ensuring
          exceptional customer experiences.
        </>
      ),
    },
    {
      id: 3,
      icon: icons.Keyfeature3,
      header: (
        <>
          Empower Agents with <br /> Cloud Console
        </>
      ),
      info: (
        <>
          Provide your agents with a powerful Cloud agent console, enabling effective customer
          interactions.
        </>
      ),
    },
    {
      id: 4,
      icon: icons.Keyfeature4,
      header: (
        <>
          Dedicated 24/7 <br /> Support
        </>
      ),
      info: (
        <>
          Access official support round the clock, providing you with peace of mind and assistance
          when you need it most.
        </>
      ),
    },
  ];

  const communicationSolutions = [
    {
      id: 1,
      img: {
        src: images.Solution1,
        alt: "",
      },
      title: "Engage Globally",
      body: "Interact with customers worldwide on WhatsApp, their preferred messaging app, with support for multi-language text, media, documents, and location sharing.",
    },
    {
      id: 2,
      img: {
        src: images.Solution2,
        alt: "",
      },
      title: "Unlock Massive Reach",
      body: "Utilize WhatsApp for Business API to access over 2 billion monthly active users globally. Strengthen your brand with personalized campaigns, automated customer support, and significant performance improvements.",
    },
    {
      id: 3,
      img: {
        src: images.Solution3,
        alt: "",
      },
      title: "Always-On Support",
      body: "Provide instant self-service and deflect queries anytime, regardless of working hours or agent availability.",
    },
    {
      id: 4,
      img: {
        src: images.Solution4,
        alt: "",
      },
      title: "Automate Notifications",
      body: "Effortlessly create notification flows based on triggers with a user-friendly interface.",
    },
    {
      id: 5,
      img: {
        src: images.Solution5,
        alt: "",
      },
      title: "Performance Metrics",
      body: "Achieve a 17X boost in notification open rates and resolve customer queries 3X faster.",
    },
    {
      id: 6,
      img: {
        src: images.Solution6,
        alt: "",
      },
      title: "WhatsApp API Partner",
      body: "Resala is your trusted partner, ensuring reliable and efficient WhatsApp API communication solutions for your global business.",
    },
    {
      id: 7,
      img: {
        src: images.Solution7,
        alt: "",
      },
      title: "Customer Service Chatbots",
      body: "Prioritize channels, reduce acquisition and retention costs, and enhance customer satisfaction.",
    },
  ];

  const useCases = [
    {
      id: 1,
      title: <>Efficient Customer Engagement</>,
      body: (
        <>
          Seamlessly send alerts, notifications, updates, 2-factor authentication messages, and
          marketing campaigns to engage and communicate with customers effectively.
        </>
      ),
    },
    {
      id: 2,
      title: <>Enhanced Customer Support</>,
      body: (
        <>
          Elevate the customer experience by creating 24/7 agent bots for addressing initial
          inquiries, diverting calls to IVR, and assisting in marketing campaign interactions.
          Ensure smooth interactions.
        </>
      ),
    },
    {
      id: 3,
      title: <>Live Chat Support</>,
      body: (
        <>
          Empower your support agents with live chat capabilities via their agent console to
          maintain seamless conversations with your valued customers.
        </>
      ),
    },
    {
      id: 4,
      title: <>Data Security</>,
      body: (
        <>
          Rest assured knowing your data is stored in a secure regional data center. Implement our
          Cloud platform while safeguarding your IT ecosystem`s integrity.
        </>
      ),
      img: {
        src: icons.Security,
        alt: "",
      },
    },
    {
      id: 5,
      title: <>Comprehensive Onboarding and Support</>,
      body: (
        <>
          Receive thorough onboarding and ongoing support to fully harness the potential of WhatsApp
          for your business, maximizing its benefits in marketing campaigns and beyond.
        </>
      ),
      img: {
        src: icons.Gear,
        alt: "",
      },
    },
  ];

  const sendEmail = (values) => {
    setIsLoading(true);
    emailjs.send(EmailJSServiceID, "demo_form", values, EmailJSPublicKey).then(
      (result) => {
        setIsLoading(false);
        toast.success("form sent", { position: "top-center" });
      },
      (error) => {
        setIsLoading(false);
        toast.error("form failed", { position: "top-center" });
      }
    );
  };
  const handleSubmit = (values, { resetForm }) => {
    sendEmail(values);
    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Loader open={isLoading} />
      <WhatsAppTemplate
        nums={nums}
        features={features}
        communicationSolutions={communicationSolutions}
        useCases={useCases}
        formFields={formFields}
        formik={formik}
      />
    </>
  );
}
