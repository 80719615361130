import { Box, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import AppContainer from "components/Atoms/Container/AppContainer";
import React, { useState } from "react";
import Slider from "react-slick";

const SlickArrowRight = ({ icon = icons?.NextArrow, currentSlide, slideCount, ...props }) => {
  const { className, style, onClick } = props;
  return (
    <Box
      className={className}
      onClick={onClick}
      component="img"
      sx={{ ...style, width: { xs: "20px", sm: "30px" }, height: { xs: "20px", sm: "25px" } }}
      src={icons.NextArrow}
    />
  );
};

const SlickArrowLeft = ({ icon = icons?.NextArrow, currentSlide, slideCount, ...props }) => {
  const { className, style, onClick } = props;
  return (
    <Box
      className={className}
      onClick={onClick}
      component="img"
      sx={{ ...style, width: { xs: "20px", sm: "30px" }, height: { xs: "20px", sm: "25px" } }}
      src={icons.PrevArrow}
    />
  );
};

const CustomDot = ({ i, onClick, isActive }) => {
  return (
    <Box
      sx={{
        width: { xs: isActive ? "17px" : "12px", md: isActive ? "14px" : "10px" },
        height: { xs: isActive ? "17px" : "12px", md: isActive ? "14px" : "10px" },
        mt: isActive ? "-2px" : "0px",
        borderRadius: "50%",
        backgroundColor: isActive ? "#000" : "rgba(0, 0, 0, 0.20)",
      }}
      onClick={onClick}
    />
  );
};

export default function CustomSlider({
  iconNext,
  iconPrev,
  dots = false,
  autoplay = true,
  autoplaySpeed = 3000,
  className = "centered",
  initialSlide = 0,
  slidesToShow,
  infinite = true,
  fade = false,
  setActiveSlides,
  children,
  header,
  centerMode = true,
  isArrow = true,
  isDotsLeft,
}) {
  const [activeDot, setActiveDot] = useState(0);
  const settings = {
    dots,
    // autoplay,
    autoplaySpeed,
    fade: fade,
    infinite: infinite,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: initialSlide,
    arrows: isArrow,
    appendDots: (dots) => (
      <Stack
        sx={{
          width: { xs: "100%", md: isDotsLeft ? "fit-content" : "100%", mt: 1 },
        }}
      >
        <ul style={{ margin: "0px", padding: 0 }}> {dots} </ul>
      </Stack>
    ),

    customPaging: (i, onClick) => <CustomDot i={i} onClick={onClick} isActive={i == activeDot} />,
    nextArrow: isArrow && <SlickArrowRight icon={iconNext} />,
    prevArrow: isArrow && <SlickArrowLeft icon={iconPrev} />,
    // beforeChange: (current, next) => setActiveSlides && setActiveSlides({ activeSlide1: next }),
    // afterChange: (current) => setActiveSlides && setActiveSlides({ activeSlide2: current }),
    beforeChange: (oldIndex, newIndex) => {
      setActiveSlides && setActiveSlides({ activeSlide1: newIndex });
      setActiveDot(newIndex);
    },
    afterChange: (index) => {
      setActiveSlides && setActiveSlides({ activeSlide2: index });
    },
    centerMode: centerMode ? centerMode : false,
    responsive: [
      {
        breakpoint: 2566,
        settings: {
          slidesToShow: slidesToShow?.xl || 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow.lg || 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: slidesToShow.md || 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: slidesToShow.sm || 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <AppContainer
      sx={{
        pb: 0,
        gap: 0,
        ".slick-dots li": {
          m: 0,
        },
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: 600,
          fontSize: { xs: "30px", sm: "36px" },
          fontFamily: "Alumni Sans",
        }}
      >
        {header}
      </Typography>
      <Slider {...settings} className={className ? className : ""}>
        {children}
      </Slider>
    </AppContainer>
  );
}
