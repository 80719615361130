import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

const VerificationCard = ({ value, label }) => (
  <Box
    sx={{
      border: `2px solid #68D6BA`,
      borderRadius: "20px",
      p: 1,
      width: "250px",
      textAlign: "center",
      width: "100%",
    }}
  >
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <Typography fontFamily="Open Sans" sx={{ fontSize: "50px", fontWeight: 700 }}>
        {value}
      </Typography>
    </motion.div>
    <Typography fontFamily="Open Sans" sx={{ fontSize: "16px", fontWeight: 600 }}>
      {label}
    </Typography>
  </Box>
);

export default VerificationCard;
