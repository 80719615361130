import { icons, images } from "assets/AssetHelper";
import { useEffect } from "react";
import VoiceTemplate from "templates/services/voice/Voice.Template";
export default function VoicePage({ size }) {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const voiceServices = [
    {
      title: "Interactive Voice Response (IVR)",
      body: "Design intuitive and efficient call-routing workflows that seamlessly direct customers to the appropriate team, enhancing overall customer experience.",
      image: images.IVR,
    },
    {
      title: "Text-to-Speech",
      body: "Leverage advanced text-to-speech technology to automate scripted calls with real-time voice messages, eliminating the need for professional recording services and ensuring consistent communication.",
      image: images.TextToSpeech,
    },
    {
      title: "Masked Calling",
      body: "Ensure privacy and security with number masking for business virtual numbers, keeping customer information confidential and protected.",
      image: images.MaskedCalling,
    },
    {
      title: "Notifications",
      body: "Deliver timely notifications to your customers, including password resets, bill reminders, and balance alerts, to keep them informed and engaged.",
      image: images.NotificationsPhone,
    },
    {
      title: "Call Recording",
      body: "Implement call recording for quality assurance, ensuring that all voice interactions are handled promptly and professionally.",
      image: images.CallRecording,
    },
    {
      title: "Real-Time Analytics",
      body: "Utilize powerful real-time analytics to monitor contact center performance, gaining valuable insights to optimize operations and improve customer satisfaction.",
      image: images.RealtimeAn,
    },
  ];

  const voiceUseCases = [
    {
      title: "Voice Reminders",
      body: "Improve delivery app accuracy with verbal directions and landmark details using voice instructions.",
      icon: icons.Keyfeature1,
    },
    {
      title: "Voice Guides",
      body: "Improve delivery app accuracy with verbal directions and landmark details using voice instructions.",
      icon: icons.VoiceGuide,
    },
    {
      title: "Lead Generation",
      body: "Capture leads efficiently by integrating IVR with CRM, using missed calls to log potential leads",
      icon: icons.LeadGeneration,
    },

    {
      title: "User Consent",
      body: "Simplify opt-in processes with a missed call mechanism, automatically recording and processing customer consent.",
      icon: icons.UserConsent,
    },

    {
      title: "User Authentication",
      body: "Enhance security with voice-based two-factor authentication, delivering OTPs through outbound calls to reduce cyber threat risks.",
      icon: icons.UserAuth,
    },

    {
      title: "Customer Support",
      body: "Upgrade customer support with IVR features like call recording, logging, forwarding, and feedback collection to streamline processes.",
      icon: icons.CustomerSupport,
    },

    {
      title: "Promotional Campaigns",
      body: "Conduct targeted promotional campaigns with Outbound Dialing (OBD), offering scalable and personalized outreach for lead generation and brand promotion.",
      icon: icons.PromoCamp,
    },
  ];

 

  return <VoiceTemplate voiceServices={voiceServices} voiceUseCases={voiceUseCases} size={size} />;
}
