import React from 'react';
import { Box, Stack, Typography } from "@mui/material";
import { useLocation } from 'react-router-dom'; // Import useLocation
import { images } from "assets/AssetHelper";
import SectionBackground from "components/Atoms/Background/SectionBackground";
import AppContainer from "components/Atoms/Container/AppContainer";
import Actions from "components/Molecules/Section/Actions";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import TalkToExpertBtn from 'components/Atoms/Buttons/TalkToExpertBtn';

export default function PageHeader({
  title,
  subTitle,
  gradientTitle,
  body,
  image,
  isActions,
  icon,
  titleStyle,
  containerStyle,
  sx,
  collapse,
  initialLength,
}) {
  const location = useLocation(); // Get the current location
  const isHomePage = location.pathname === '/'; // Check if the current path is '/'

  return (
    <SectionBackground
      sx={{
        backgroundPosition: {
          xs: !image && !isActions ? "0% 150%" : "0% 0%",
          sm: "0% -50%",
          lg: "0% -100%",
        },
        backgroundImage: {
          xs: `url(${images.WaveBackground2})`,
          md: `${
            image && "linear-gradient(90deg, rgba(0,0,0,0) 100%, rgba(255,255,255,1) 70%)"
          },url(${images.WaveBackground})`,
        },
        backgroundSize: {
          xs: image && isActions ? "100% 28%" : image || isActions ? "100% 45%" : "100% 110%",
          sm: "100% 67%",
          md: "100% 90%",
        },
        minHeight: { xs: !image && !isActions ? "250px" : "auto", sm: !image ? "450px" : "auto" },
        mt: { xs: -10, sm: -13, md: -10 },
        justifyContent: "center",
      }}
    >
      <AppContainer
        direction={{ xs: "column", md: "row" }}
        justifyContent={image ? "space-between" : "center"}
        alignItems="center"
        gap={2}
        sx={{ mt: { xs: !image && !isActions ? 0 : 5, md: 5 }, ...sx }}
      >
        {/* LEFT STACK */}
        <Stack
          alignItems={{ xs: "center", md: image ? "start" : "center" }}
          justifyContent="center"
          gap={{ xs: 2, sm: 4 }}
        >
          <Stack
            alignItems={{ xs: "center", md: image ? "start" : "center" }}
            gap={3} 
            mt={20}
          >
            <Title
              text={title}
              subTitle={subTitle}
              gradientText={gradientTitle}
              sx={{
                fontSize: { xs: "50px", sm: "64px" },
                fontWeight: 600,
                ...titleStyle,
              }}
              containerStyle={containerStyle}
              icon={icon}
            />

            <Body
              text={body}
              isImage={image}
              sx={{ fontSize: { xs: "16px", sm: "22px" }, width: { xs: "100%", sm: "80%" } }}
              collapse={collapse}
              initialLength={initialLength}
            />
          </Stack>
          {isActions && (isHomePage ? (
            <TalkToExpertBtn
              sx={{
                borderRadius: "46px",
                px: "30px",
                height: 48,
                fontWeight: 600,
                fontSize: "26px",
                whiteSpace: "nowrap",
                fontFamily: "Alumni Sans",
                color: "black",
                border: "2px solid black",
                backgroundColor: "white",
                background: "none",
                "&:hover": {
                  border: "2px solid black",
                },
              }}
              variant="outlined"
              title="Contact An Expert"
            />
          ) : (
            <Actions />
          ))}
        </Stack>

        {/* RIGHT STACK */}
        {image && image}
      </AppContainer>
    </SectionBackground>
  );
}
