import * as Yup from "yup";

export const formFields = [
  { label: "First Name", type: "text", name: "firstName" },
  { label: "Last Name", type: "text", name: "lastName" },
  { label: "Email Address", type: "text", name: "email" },
  { label: "Company", type: "text", name: "company" },
  { label: "Website", type: "text", name: "website" },
  { label: "Job Role", type: "text", name: "jobRole" },
  { label: "Phone Number", type: "phone", name: "phone" },
  {
    label: "Do you have a verified Facebook Page?",
    type: "radio",
    name: "isVerified",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  },
  {
    label: "Tell us more about your customer communication goals",
    type: "text",
    name: "goals",
    width: "100%",
  },
];

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  website: "",
  jobRole: "",
  phone: "",
  isVerified: "",
  goals: "",
};

const phoneNumberRegex =
  /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
export const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  company: Yup.string().required("Required"),
  website: Yup.string().required("Required"),
  jobRole: Yup.string().required("Required"),
  phone: Yup.string().matches(phoneNumberRegex, "invalid phone number").required("required"),
  isVerified: Yup.string().required("Required"),
  goals: Yup.string().required("Required"),
});
