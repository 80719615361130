import ContactUsForm from "components/Organisms/contact-us/ContactUsForm";
import PageHeader from "components/Organisms/page-header";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
export default function ContactUsTemplate({ formFields, formik }) {
  return (
    <>
      <PageHeader
        title="Contact Us"
        body="Have any question ? We'd love to hear from you. Here's how you can reach us..."
      />
      <ContactUsForm
        title="Get in touch"
        body="Kindly provide the details below, and our experts will promptly reach out to you."
        formik={formik}
        formFields={formFields}
      />
      <NewsLetter/>
    </>
  );
}
