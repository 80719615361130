import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import TalkToExpertBtn from "components/Atoms/Buttons/TalkToExpertBtn";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        backgroundColor: "white",
        px: { xs: 2, sm: 20 },
        borderRadius: 2,
        margin: 0,
      }}
    >
      {children}
    </Stack>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DIDTabs({ numbers }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ margin: 0 }}>
      <Box sx={{ display: "flex",  justifyContent: "center", mb: { xs: 3, sm: 5 } }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          ScrollButtonComponent={({ direction, onClick }) => null} 
          sx={{
            ".MuiTab-root": {
              color: "#727272",
              textTransform: "capitalize",
              fontSize: { xs: "16px", sm: "18px" },
              fontWeight: "400",
              fontFamily: "Alef",
              backgroundColor: "rgba(0, 0, 0, 0.10)",
              borderRadius: "48px",
              px: 3,
              maxWidth: "fit-content",
              mx: "10px",
            },
            ".Mui-selected": {
              background: "linear-gradient(94deg, #68D6BA 7.22%, #EC9EC0 97.75%)",
              textDecoration: "capitalize",
              color: "white !important",
            },
            display: "flex",
            direction: "column",
            justifyContent: "space-between",
          }}
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          {numbers.map((feature, index) => (
            <Tab key={index} label={feature.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>

        <CustomTabPanel value={value}
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
        gap="97px"
        >
          {/* <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems="center"
            justifyContent="space-between"
            sx={{ gap: { xs: 3, sm: 15 }, mb: 3 }}
          > */}
            <Stack
              sx={{
                width: { xs: "100%", sm: "80%", md: "50%", lg: "40%" },
                backgroundColor: "white",
                margin: 0,
              }}
            >
              <motion.div
                animate={{ opacity: [0, 1], y: [100, 0] }}
                transition={{
                  ease: "easeOut",
                  duration: 1.3,
                  delay: 0.1,
                  type: "spring",
                  stiffness: 100,
                }}
                key={value ? value : "empty"}
              >
                <Box component="img" src={numbers[value].image} width="100%" />
              </motion.div>
            </Stack>
            <Stack
              sx={{
                width: { xs: "100%", md: "100%" },
                backgroundColor: "white",
                borderRadius: 2,
                gap: { xs: "16px", md: "20px" },
                alignItems: "flex-start",
              }}
            >
              {/* Add Title */}
              <Typography
                sx={{
                  fontFamily: "Alef",
                  fontSize: { xs: "18px", md: "20px" },
                  fontWeight: 700,
                }}
              >
                {numbers[value].title}
              </Typography>
              {/* Add Body */}
              <Typography
                sx={{
                  color: "#3C3C3CCC",
                  fontFamily: "Alef",
                  fontWeight: "400",
                  fontSize: "18px",
                  lineHeight: "31px",
                }}
              >
                {numbers[value].body}
              </Typography>
              {/* Bullet Points */}
              <ul
                style={{
                  color: "#636363",
                  padding: "0",
                  margin: "0",
                  listStyleType: "disc",
                  paddingLeft: "30px",
                }}
              >
                {numbers[value].content.map((point, idx) => (
                  <li
                    key={idx}
                    style={{
                      fontFamily: "Alef",
                      fontSize: "18px",
                      lineHeight: "24.53px",
                      fontWeight: "700",
                      marginBottom: "10px",
                      listStylePosition: "outside", // Ensure the bullets are outside the list
                      paddingLeft: "10px", // Create space between the bullet and the text
                    }}
                  >
                    {point}
                  </li>
                ))}
              </ul>
            </Stack>
          {/* </Stack> */}
        </CustomTabPanel>
      </Box>
  );
}
