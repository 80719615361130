import { Box, Button, Stack } from "@mui/material";
import TalkToExpertBtn from "components/Atoms/Buttons/TalkToExpertBtn";
import NestedMenu from "components/Atoms/Menu/NestedMenu";

export default function NavLinks({
  mainNavItems,
  secondaryNavItems,
  navLinkStyle,
  isActive,
  handleNavLinkClicked,
  open,
  handleClick,
  handleClose,
  anchorEl,
  setActivePath,
  currentMenu
}) {
  return (
    <>
      <Box sx={{ display: { xs: "none", lg: "block", flexGrow: 1 }  }   }>
        {mainNavItems.map((item, index) =>
          item.type === "normal" ? (
            <Button
              key={index}
              onClick={() => handleNavLinkClicked(item.path, item.isExternal)}
              sx={{
                color: "#000",
                ...navLinkStyle(isActive(item.path)),
              }}
            >
              {item.label}
            </Button>
          ) : (
            <NestedMenu
              key={index}
              item={item}
              open={open && currentMenu === item.label}
              handleClick={(e) => handleClick(e, item.label)}
              handleClose={handleClose}
              anchorEl={anchorEl}
              setActivePath={setActivePath}
              navLinkStyle={navLinkStyle}
            />
          )
        )}
      </Box>
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        sx={{ display: { xs: "none", lg: "flex" }, flexWrap: "wrap" }}
      >
        <Stack direction="row">
          {secondaryNavItems.map((item, index) => (
            <Stack
              key={index}
              sx={{
                borderRight: index !== secondaryNavItems.length - 1 && "2px solid black",
                px: 1,
                pr: index !== secondaryNavItems.length - 1 && 2,
                height: "25px",
                
              }}
            >
              <Button
                onClick={() => handleNavLinkClicked(item.path, item.isExternal)}
                sx={{
                  ...navLinkStyle(isActive(item.path)),
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: 500,
                  fontFamily: "Alegreya Sans",
                  height: "100%",
                  m: 0,
                  mt: 0.5,
                  pb: 2,
                }}
              >
                {item.label}
              </Button>
            </Stack>
          ))}
        </Stack>
        <TalkToExpertBtn sx={{ fontSize: "20px" }} />
      </Stack>
    </>
  );
}
