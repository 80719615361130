import * as React from "react";
import { Box, Card, CardContent, Typography, Stack } from "@mui/material";
import { icons } from "assets/AssetHelper";
import AppContainer from "components/Atoms/Container/AppContainer";

export default function RCSCards({ featuresData }) {
  return (
    <AppContainer py={4}>
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={2}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        gap:"68px",
        mt: 5,
        mb: "64px",
      }}
      
    >
      {featuresData.map((feature, index) => (
        <Box
          key={index}
          sx={{
            width: { xs: "100%", md: "40%" },
            borderRadius: "20px",
            height: "540px",
            background: "linear-gradient(180deg, #D4F4DD 0%, #F3CAD6 100%)",
            border: "2px solid #00ACC1",
            padding: "16px",
          }}
        >
          <CardContent
           sx={{
            flexGrow: 1, // Makes content fill the card's height
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center content vertically
          }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{
                fontWeight: "600",
                mb: 4, // 32px margin between the title and the items
                fontFamily: "Alumni Sans",
                fontSize: "36px"
              }}
            >
              {feature.title}
            </Typography>
            <Stack gap="24px"> 
              {feature.items.map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Alef",
                    fontSize: "16px",
                    gap:"22px"
                  }}
                >
                  <Box
                    component="img"
                    src={icons.Tick}
                    sx={{ marginRight: 1 }}
                  />
                  <Typography fontSize="20px" fontWeight="700">{item}</Typography>
                </Box>
              ))}
            </Stack>
          </CardContent>
        </Box>
      ))}
    </Stack>
    </AppContainer>
  );
}
