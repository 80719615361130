import Header from "components/Organisms/page-header";
import { images } from "assets/AssetHelper";
import SMSPricing from "components/Organisms/pricing/SMSPricing";
import Introduction from "components/Organisms/services/SMS/promotional/Introduction";
import TalkToExpertBtn from "components/Atoms/Buttons/TalkToExpertBtn";
import Actions from "components/Molecules/Section/Actions";
import NewsLetter from "components/Molecules/Footer/NewsLetter";

export default function SMSPricingTemplate({ size, formFields, formik, prices }) {
  return (
    <>
      <Header
        title="SMS Pricing"
        body="Experience cost-effective pay-as-you-go SMS pricing with inclusive add-on features for a competitive edge"
        collapse={size <= 900}
        initialLength={70}
      />

      <SMSPricing
        title="SMS pricing"
        body="Big Value. Transparent Pricing. Discover Resala's SMS API pricing, tailored for all your connection needs"
        prices={prices}
      />
      <Introduction
        title="Committed Spend Discounts"
        body="Unlock bigger savings as your volumes rise - more discounts await you."
        image={images.Discounts}
        position="left" // Image first
        otherChildren={
          <TalkToExpertBtn title="Contact An Expert" sx={{ mx: { xs: "auto", md: "0" } }} />
        }
      />

      <Introduction
        title="Get started now. Sign up for free!"
        body="Create your account now and enjoy trial credits or reach out to us for personalized assistance."
        image={images.SignUp}
        position="right" // Image second
        otherChildren={<Actions title="Contact An Expert" sx={{ mx: { xs: "auto", md: "0" } }} />}
      />

      <NewsLetter />
    </>
  );
}
