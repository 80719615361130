import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export default function Title({ text, gradientText, text2, icon, sx,containerStyle }) {
  return (
    <Typography
      variant="body"
      fontFamily="Alumni Sans"
      fontWeight={600}
      fontSize={{ xs: "30px", md: "36px" }}
      sx={{...sx ,...containerStyle} }
    >
      {text}
      {gradientText && (
        <Typography
          fontFamily="Alumni Sans"
          fontWeight="500"
          fontSize={{ xs: "30px", md: "36px" }}
          sx={{
            background: `linear-gradient(92deg, #4DDDB7 5%, #EC9EC0 80%)`,
            WebkitBackgroundClip: "text",
            color: "transparent",
            display: "inline",
            mx: 1.5,
            fontWeight: 700,
            ...sx,
          }}
        >
          {gradientText}
        </Typography>
      )}
      {text2 && text2}
      {icon && (
        <Box component="img" src={icon} width="48px" sx={{ color: "red !important", mx: 2 }} />
      )}
    </Typography>
  );
}
