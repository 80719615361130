import { Box } from "@mui/system";

export default function FadingBorder({ sx }) {
  const fadingBorderBottomStyle = {
    p: 0.2,
    backgroundImage:
      "linear-gradient(100deg, rgba(148, 243, 218, 0.00) 10%, #94F3DA 48.96%, rgba(148, 243, 218, 0.00) 95%)",
    ...sx,
  };

  return <Box sx={fadingBorderBottomStyle} />;
}
