

import { Box, Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import { motion } from "framer-motion";

export default function WhyResala({ title, data }) {
  const { white } = useThemePalette();
  return (
    <Stack sx={{ backgroundColor: white }}>
      <AppContainer
        direction={{ xs: "column", md: "row" }}
        gap={{ xs: 5, md: 5 }}
        py={5}
        sx={{alignItems : "center"}}
      >
        {/* CCONTENT */}
        <Stack gap={{ xs: 3, md: 5 }} py={2}>
  <Title text={title} />
  {data.map((elem, i) => (
    <Stack flexDirection={'column'} key={i} gap={3} sx={{alignItems : {xs:"center",md:"stretch"}}}>
        <Stack flexDirection={'row'} alignItems={"stretch"}  gap={1}>
            <Box
                sx={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
                background: "linear-gradient(128deg, #68D6BA 18.75%, #E7A1C0 86.92%)",
                // cursor: "pointer",
                }}
            />
            <Typography fontFamily={"Alef"} fontSize={"18px"} fontWeight={700}>
            {elem.title}
            </Typography>
        </Stack>

        <Body text={elem.body} sx={{ textAlign: { xs: "center", md: "start" } , ml:{md:"6%"} }} />

    </Stack>
  ))}
</Stack>

        {/* IMAGE */}
        <Stack  sx={{ overflow: "hidden" }} width={{ xs: "100%", md: "100%", lg: "90%" }}  >
          <motion.div
            whileInView={{ x: [-200, 0] }}
            transition={{
              ease: "easeOut",
              duration: 1,
              delay: 0.1,
              type: "spring",
              stiffness: 100,
            }}
          >
            <Box
              component="img"
              src={images.HandShake}
              sx={{
                width: { xs: "100%", sm: "100%", md: "90%", lg: "90%" }, // Responsive width
                maxWidth: "100%", // Ensure it doesn't exceed container width
                height: "auto", // Maintain aspect ratio
              }}
            />
          </motion.div>
        </Stack>

      </AppContainer>
    </Stack>
  );
}
