import { Stack, Box, Typography } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import Title from "components/Molecules/Section/Title";
import GradientBorderedCard from "components/Molecules/Cards/GradientBorderedCard";

export default function KeyFeatures({ title, features }) {
  return (
    <AppContainer sx={{ py: 5, pb: 15 }} gap={7}>
      <Title text={title} sx={{ mx: "auto" }} />
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        sx={{ rowGap: 10, columnGap: 5 }}
      >
        {features.map((item, i) => (
          <GradientBorderedCard
            key={i}
            title={item.title}
            icon={item.icon}
            cardsx={{ pt: 8, width: { xs: "100%", md: "22%" }, py: 2, px: 0.9, gap: 2 }}
            iconsx={{ left: { xs: "42%", sm: "45%", md: "35%", lg: "35%", xl: "40%" } }}
            animated
          >
            <Typography fontFamily="Alef" fontSize={{ xs: "18px", md: "16px" }} fontWeight={700}>
              {item.header}
            </Typography>
            <Typography fontFamily="Alef" fontSize={{ xs: "16px", md: "16px" }}>
              {item.info}
            </Typography>
          </GradientBorderedCard>
        ))}
      </Stack>
    </AppContainer>
  );
}
